<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Adicionar nova Area de Atuação
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">
                <div class="form-outline">
                  <label class="form-label" for="formNomeAreaAtuacao">Nome da Area de Atuação</label>
                  <input type="text" id="formNomeAreaAtuacao" formControlName="formNomeAreaAtuacao" [value]="form.get('formNomeAreaAtuacao')"
                    name="formNomeAreaAtuacao" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formNomeAreaAtuacao')"
                    label="nome da area de atuação"></app-field-error-message>
                </div>
              </div>              
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                  (click)="SalvaAreaAtuacao();">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
