import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParametrosRecord } from '../Relatorios/models/ret-relatorio';
import { take } from 'rxjs';
import { MessageService } from 'src/app/Projeto/message/message.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RotinasService } from './rotinas.service'


@Component({
  selector: 'app-rotinas',
  templateUrl: './rotinas.component.html',
  styleUrls: ['./rotinas.component.css']
})

export class RotinasComponent implements OnInit {

  constructor(
    private router: Router,
    public frmBui: FormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private utilsservice: UtilsService,
    private relService: RotinasService
  ) { }

  dataInicial: Date = new Date();
  dataFinal: Date = new Date();

  form: FormGroup;

  ngOnInit() {
    this.dataInicial = new Date(this.dataInicial.getUTCFullYear(), this.dataInicial.getUTCMonth(), 20);
    this.dataFinal = Utils.UltimoDiaMes(new Date());
    this.criarForm();
  }


  criarForm() {
    this.form  = this.frmBui.group({

      DataInicial: [this.dataInicial],
      DataFinal: [this.dataFinal],

    })

  }

  changeDtIni($event: any) {
    //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
    this.dataInicial = new Date($event);
  }

  changeDtFim($event: any) {
    //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
    this.dataFinal = new Date($event);
  }

  Gera(relato: string)
  {

    let relatorio: ParametrosRecord = new ParametrosRecord;
    relatorio.NomeRelatorio = relato;
    relatorio.DataInicial = this.dataInicial;
    relatorio.DataFinal = this.dataFinal;
    this.spinner.show();
    try {
      this.relService.ExecutaRotina(relatorio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            else {
              this.messageService.showAlertSuccess("Geração concluída");
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

}
