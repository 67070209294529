export class CategoriaHistorico {
  CodigoSocioCategoriaHist: number;
  CodigoCategoria: number;
  DescricaoCategoria: string;
  CodigoSocio: number;
  DataCriacao: Date;
  DataEncerramento: Date;
  Observacoes: string;
  UsuarioCriador: string;

  constructor() {
    this.CodigoSocioCategoriaHist = 0;
    this.CodigoCategoria = 0;
    this.DescricaoCategoria = '';
    this.CodigoSocio = 0;
    this.Observacoes = '';
    this.UsuarioCriador = '';
  }

}
