export class Categoria {
  CodigoCategoria: number;
  DescricaoCategoria: string;
  PagaMensalidade: boolean;
  ValorMensalidade: number;

  constructor() {
    this.CodigoCategoria = 0;
    this.DescricaoCategoria = '';
    this.PagaMensalidade = false;
    this.ValorMensalidade = 0.00;
  }

}

export class CategoriaHistorico {
  CodigoCategoria: number;
  DescricaoCategoria: string;
  CodigioSocio: number;
  DataCriacao: Date;
  DataEncerramento: Date;
  Observacoes: string;
  UsuarioCriador: string;

  constructor() {
    this.CodigoCategoria = 0;
    this.DescricaoCategoria = '';
    this.CodigioSocio = 0;
    this.Observacoes = '';
    this.UsuarioCriador = '';
  }
}
