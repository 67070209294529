import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { CategoriaService } from '../../../Sistema/categoria/categoria.service';
import { Categoria } from '../models/categoria';
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-categoria-lista',
  templateUrl: './categoria-lista.component.html',
  styleUrls: ['./categoria-lista.component.css'],
})
export class CategoriaListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private categoriaService: CategoriaService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
  ) {}

  categorias: Categoria[] = [];
  categoriasOriginal: Categoria[] = [];
  novaCategoria: Categoria = new Categoria();
  DadosExcell: any = [];
  ColunasExcell: any = [];

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaCategoria();
  }

  BuscaCategoria(): void {
    this.spinner.show();
    try {
      this.categoriaService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.categorias = response.CategoriaLista;
              this.categoriasOriginal = response.CategoriaLista;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  
    Edicao(CodCategoria: number, DescricaoCategoria: string, PagaMensalidade: boolean, ValorMensalidade: number) {
        this.router.navigateByUrl('/administrativo/categoria/edicao', { state: { 
            codigo: CodCategoria, descricao: DescricaoCategoria, 
            mensalidade: PagaMensalidade, valor: ValorMensalidade
        }});
    }

    Adicionar() {
        console.log("ADICIONAR = CATEGORIA LISTA COMPONENT")
        this.spinner.show();
        this.novaCategoria = new Categoria();
        this.novaCategoria.CodigoCategoria = -1;
        this.spinner.hide();
        this.router.navigateByUrl('/administrativo/categoria/edicao', {
            state: { novaCategoria: this.novaCategoria, Origem: 'LISTA' },
        });
    } 

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.categoriasOriginal.filter(function (d: any) {
      return (
        d.DescricaoCategoria.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.categorias = temp;
    return true;
}

  generateExcell() {
    var nomeArquivo: string = 'RelatorioCategorias';
    var Titulo: string = 'Relatório Categorias';

    this.ColunasExcell = [];
    this.ColunasExcell.push('Código');
    this.ColunasExcell.push('Descrição');
    this.DadosExcell = [];

    this.categorias.forEach((item: Categoria) =>{
        const varExcell: any = [];
        varExcell.push(item.CodigoCategoria);
        varExcell.push(item.DescricaoCategoria);
        this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
        nomeArquivo,
        Titulo,
        this.ColunasExcell,
        this.DadosExcell
      );
  }

}
