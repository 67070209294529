export enum alertTypes {
  DANGER = 'danger',
  SUCCESS = 'success',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark'
}

export enum alertIds {
DANGER = 9900,
SUCCESS = 9901,
PRIMARY = 9902,
SECONDARY = 9903,
WARNING = 9904,
INFO = 9905,
LIGHT = 9906,
DARK = 9907
}