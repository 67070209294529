export class PlanoConta {
    IdPlanoConta: number;
    CodigoPlanoConta: string;
    DescricaoPlanoConta: string;
    TipoPlanoConta: string;
    CodigoContaPai: number;
    ContaPai: string  
    Ativo: boolean;
    SomaRelatorio: boolean;
    ColunaRelatorio: string;

    constructor() {
      this.IdPlanoConta= 0;
      this.CodigoPlanoConta= '';
      this.DescricaoPlanoConta = '';
      this.TipoPlanoConta = '';
      this.CodigoContaPai = 0;
      this.ContaPai = '';
      this.Ativo = false;
      this.SomaRelatorio = false;
    }

    carregar(objeto: any): void {
        this.IdPlanoConta = objeto.CodigoPlanoConta || 0;
        this.CodigoPlanoConta = objeto.CodTextoPlanoConta || '';
        this.DescricaoPlanoConta = objeto.DescricaoPlanoConta || '';
        this.TipoPlanoConta = objeto.TipoPlanoConta || '';
        this.CodigoContaPai = objeto.CodigoPlanoContaPai || '';
        this.ContaPai = objeto.ContaPai || '';
        this.Ativo = objeto.AtivoPlanoConta || '';
        this.SomaRelatorio = objeto.SomaRelatorio || '';
    }

    static saoDiferentes(planoConta1: PlanoConta, planoConta2: PlanoConta): boolean {
        return planoConta1.IdPlanoConta !== planoConta2.IdPlanoConta ||
               planoConta1.CodigoPlanoConta !== planoConta2.CodigoPlanoConta ||
               planoConta1.DescricaoPlanoConta !== planoConta2.DescricaoPlanoConta ||
               planoConta1.TipoPlanoConta !== planoConta2.TipoPlanoConta ||
               planoConta1.CodigoContaPai !== planoConta2.CodigoContaPai ||
               planoConta1.Ativo !== planoConta2.Ativo ||
               planoConta1.SomaRelatorio !== planoConta2.SomaRelatorio;
    }

    toJson(): any {
        return {
            CodTextoPlanoConta: this.CodigoPlanoConta,
            CodigoPlanoConta: this.IdPlanoConta,
            TipoPlanoConta: this.TipoPlanoConta?.charAt(0),
            DescricaoPlanoConta: this.DescricaoPlanoConta,
            ColunaRelatorio: this.ColunaRelatorio,
            CodigoPlanoContaPai: this.CodigoContaPai,
            AtivoPlanoConta: this.Ativo,
            SomaRelatorio: this.SomaRelatorio,
        };
    }
    toDict(planoContaOriginal: PlanoConta[]): any {
        return planoContaOriginal.map(planoConta => ({
            Ativo: planoConta.Ativo,
            CodigoContaPai: planoConta.CodigoContaPai,
            CodigoPlanoConta: planoConta.CodigoPlanoConta,
            ContaPai: planoConta.ContaPai,
            DescricaoPlanoConta: planoConta.DescricaoPlanoConta,
            IdPlanoConta: planoConta.IdPlanoConta,
            SomaRelatorio: planoConta.SomaRelatorio,
            TipoPlanoConta: planoConta.TipoPlanoConta,
            DescricaoSelect: planoConta.CodigoPlanoConta + ' - ' + planoConta.DescricaoPlanoConta,
        }));
    }
}  
