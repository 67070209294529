import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { JwtInterceptor } from './Projeto/interceptors/jwt.interceptor';
import { BaseInterceptor } from './Projeto/interceptors/base.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { ProjectModule } from './Projeto/project.module';

import ptBr from '@angular/common/locales/pt';

// Locale
registerLocaleData(ptBr)

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    ProjectModule,
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
