import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetPrevisao, RetPrevisaoLista } from './models/ret-previsao';

import { Previsao } from './models/previsao';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';


@Injectable({
  providedIn: 'root'
})

export default class PrevisaoService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.PrevisaoWS}`;
  }

  GetListaGeral(): Observable<RetPrevisaoLista> {
    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetPrevisaoLista>(url).pipe(
      take(1),
      map((response: RetPrevisaoLista) => {
        return response;
    })
    );
    
  }

  SalvaPrevisao(previsao: Previsao): Observable<RetError> {
    let bodyString = JSON.stringify(previsao.toJson());

    const url = `${this.baseURL}/SalvaPrevisao`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
        take(1),
        map((response: RetError) => {
            return response;
        })
    );
  }   
}
