import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetSalas , RetOcupacao, RetSala } from './models/ret-salas';
import { LiberaSalaParametros } from './models/salaOcupacao';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { SalvaSalaParametros } from './models/sala';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root'
})

export class SalasService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.SalasWS}`;
  }

  ListaSalas(): Observable<RetSalas> {

    const url = `${this.baseURL}/ListaSalas`;
    return this.httpClient.get<RetSalas>(url).pipe(
      take(1),
      map((response: RetSalas) => {
        return response;
      })
    );

  }

  GetSalaByCodigo(CodigoSala: number): Observable<RetSala> {
    const params = new HttpParams()
    .set('CodigoSala', CodigoSala)

    const url = `${this.baseURL}/GetSalaByCodigo`;
    return this.httpClient.get<RetSala>(url,{ "params": params }).pipe(
      take(1),
      map((response: RetSala) => {
        return response;
      })
    );

  }

  GetOcupacaoSala(CodigoSala: number): Observable<RetOcupacao> {

    const params = new HttpParams()
    .set('CodigoSala', CodigoSala)

    const url = `${this.baseURL}/GetOcupacaoSala`;
    return this.httpClient.get<RetOcupacao>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetOcupacao) => {
        return response;
      })
    );

  }

  LiberaSala(CodigoSala: number, HoraConsulta: number, DiaSemanaConsulta: number, CodigoTerapeuta: number, CodigoPaciente: number): Observable<RetOcupacao> {

    let LiberaSalaPar: LiberaSalaParametros = new LiberaSalaParametros;
    LiberaSalaPar.CodigoSala = CodigoSala;
    LiberaSalaPar.HorarioConsulta = HoraConsulta;
    LiberaSalaPar.DiaSemanaConsulta = DiaSemanaConsulta;
    LiberaSalaPar.CodigoTerapeuta = CodigoTerapeuta;
    LiberaSalaPar.CodigoPaciente =  CodigoPaciente;

    let bodyString = JSON.stringify(LiberaSalaPar);

    const url = `${this.baseURL}/LiberaSala`;
    return this.httpClient.post<RetOcupacao>(url, bodyString ).pipe(
      take(1),
      map((response: RetOcupacao) => {
        return response;
      })
    );

  }

  SalvaSala(CodigoSala: number, NomeSala: string, TaxaSala: number, SalaOnLine: boolean, SalvaValorTodas: boolean): Observable<RetError> {

    let SalvaSalaPar: SalvaSalaParametros = new SalvaSalaParametros;
    SalvaSalaPar.CodigoSala = CodigoSala;
    SalvaSalaPar.NomeSala = NomeSala;
    SalvaSalaPar.TaxaSala = TaxaSala;
    SalvaSalaPar.SalaOnLine = SalaOnLine;
    SalvaSalaPar.SalvaValorTodas = SalvaValorTodas;

    let bodyString = JSON.stringify(SalvaSalaPar);

    const url = `${this.baseURL}/SalvaSala`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }

}
