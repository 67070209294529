<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="align-items-center justify-content-center h-100">

        <div class="card-title-form">Histórico das Mensalidades de {{ NomeSocio }}
          <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar" placement="top"
            [adaptivePosition]="false">
            <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();"><i
                class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
          </span>
        </div>

        <div style="margin-top: 0.2%;margin-bottom: 0.2%;" class="row">
          <span class="w-75">
            <input type="text" #search id="search" class="form-control form-control-lg "
              placeholder="Pesquisar descrição, datas, valores ou núnero boleto na listagem abaixo..."
              (keyup)="updateFilter($event)" />
          </span>
          <span class="w-25">
            <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
            [adaptivePosition]="false" (click)="generateExcell()"><i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
          </span>
        </div>

        <div class="card-body-form">
          <div class="table-responsive table-responsive-overflow-450">
            <ngx-datatable #myTable class="bootstrap ngx-datatable" [rows]="mensalidades"
              [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
              [scrollbarH]="false" [scrollbarV]="false" [limit]="6" [messages]="my_messages">

              <ngx-datatable-column prop="DescricaoMensalidade" [draggable]="false" [flexGrow]="3">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DescricaoMensalidade}}" placement="auto"
                    [adaptivePosition]="false">{{row.DescricaoMensalidade}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="DataVencimento" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Data Vencimento</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Vencto</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DataVencimento | date: 'dd/MM/yyyy'}}" placement="auto"
                    [adaptivePosition]="false">{{row.DataVencimento | date: 'dd/MM/yyyy'}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="ValorVencimento" [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'"
                [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Valor Vencimento</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Valor</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.ValorVencimento | currency: 'BRL'}}"
                    placement="auto" [adaptivePosition]="false">{{row.ValorVencimento | currency: 'BRL'}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="DataPagamento" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Data Pagamento</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Pagto</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.DataPagamento | date: 'dd/MM/yyyy'}}" placement="auto"
                    [adaptivePosition]="false">{{row.DataPagamento | date: 'dd/MM/yyyy'}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="ValorPagamento" [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'"
                [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Valor Pagamento</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Valor</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.ValorPagamento | currency: 'BRL'}}" placement="auto"
                    [adaptivePosition]="false">{{row.ValorPagamento | currency: 'BRL'}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column prop="NroBoletoBanco" [draggable]="false" [flexGrow]="2">
                <ng-template ngx-datatable-header-template>
                  <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Nro Boleto Banco</span>
                  <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Nro Banco</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span containerClass="customClass" tooltip="{{row.NroBoletoBanco}}" placement="auto"
                    [adaptivePosition]="false">{{row.NroBoletoBanco}}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Ações" [sortable]="false" [cellClass]="'ngx-direita'"
                [headerClass]="'ngx-direita'" [draggable]="false" [flexGrow]="1">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                  <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top"
                    [adaptivePosition]="false" (click)="Edicao(row.CodigoMensalidade, row.Sigla); ">
                    <i class="fa-regular fa-pen-to-square"></i></span>
                  <span class="iconeTamanho" containerClass="customClass" tooltip="Exclusão" placement="top" style="padding-left: 6% !important;padding-right: 4% !important;"
                    [adaptivePosition]="false" (click)="openModalExportar(confirmaExclusao, row.CodigoMensalidade, row.DescricaoMensalidade, row.DataVencimento, row.Sigla);">
                    <i class="fa-solid fa-trash"></i></span>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>

          <div class="top1">
            <div class="table-responsive linha-de-botoes">
              <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<ng-template #confirmaExclusao>
  <div>
    <div class="modal-header">
      <h5 class="modal-title">Confirmação de Exclusão</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"></span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirma a exclusão da mensalidade '{{DescricaoMensalidade}}' cujo vencimento é {{DataVencimento | date: 'dd/MM/yyyy'}}?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ExcluiMensalidadeByCodigo()">Sim</button>
      <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
    </div>
  </div>
</ng-template>
