import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticaService } from '../../Autenticacao/autentica.service';

@Component( {
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.css' ],
    //preserveWhitespaces: true
} )

@Injectable()
export class HeaderComponent implements OnInit
{

    private janelaExterna: Window | null = null;
    //@ViewChild( CdkPortal ) portal: CdkPortal;

    constructor (
        public authService: AutenticaService,
        private router: Router,

    ) { }

    ngOnInit(): void
    {
    }

    onLogout(): void
    {
        this.authService.logout();
    }

}
