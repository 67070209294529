// case "Perfil":
// case "TipoEndereco":
// case "TipoAtendimento":
// case "TipoConsultaEnsino":
// case "SimNao":
// case "StatusCompraOrca":
// case "StatusEnsino":
// case "TipoLancamento":
// case "DiaSemana":
// case "Hora":
// case "TipoConsulta":
// case "SituacaoConsulta":
// case "TipoEvento":
// case "SituacaoPartEvento":
// case "TipoEnsino":
// case "SituacaoPartEnsino":
// case "Sexo":
// case "EstadoCivil":
// case "ColunaRel":

export class Lista {
  Nome: string;
  Valor: string;
  Descricao: string;

  constructor() {
    this.Nome = '';
    this.Valor = "";
    this.Descricao = '';
  }

}

