import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { AreaAtuacaoService } from '../../../Sistema/areaatuacao/areaatuacao.service';
import { AreaAtuacao } from '../models/areaatuacao';
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-areaatuacao-lista',
  templateUrl: './areaatuacao-lista.component.html',
  styleUrls: ['./areaatuacao-lista.component.css'],
})
export class AreaAtuacaoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private areaAtuacaoService: AreaAtuacaoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
  ) {}

  areas: AreaAtuacao[] = [];
  areasOriginal: AreaAtuacao[] = [];
  novaAreaAtuacao: AreaAtuacao = new AreaAtuacao();
  DadosExcell: any = [];
  ColunasExcell: any = [];

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaAreas();
  }

  BuscaAreas(): void {
    this.spinner.show();
    try {
      this.areaAtuacaoService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.areas = response.AreaAtuacaoLista;
              this.areasOriginal = response.AreaAtuacaoLista;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  
  Edicao(CodAreaAtuacao: number, NomeAreaAtuacao: string) {
    this.router.navigateByUrl('/administrativo/areaatuacao/edicao', { state: { codigo: CodAreaAtuacao, nome: NomeAreaAtuacao }});    
  }

  Adicionar() {
    console.log("ADICIONAR = AREAATUACAO LISTA COMPONENT")
    this.spinner.show();
    this.novaAreaAtuacao = new AreaAtuacao();
    this.novaAreaAtuacao.CodigoAreaAtuacao = -1;
    this.spinner.hide();
    this.router.navigateByUrl('/administrativo/areaatuacao/edicao', {
      state: { novaAreaAtuacao: this.novaAreaAtuacao, Origem: 'LISTA' },
    });

  } 

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.areasOriginal.filter(function (d: any) {
      return (
        d.NomeAreaAtuacao.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.areas = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioAreasAtuação';
    var Titulo: string = 'Relatório Areas de Atuação';

    this.ColunasExcell = [];
    this.ColunasExcell.push('Código');
    this.ColunasExcell.push('Descrição');
    this.DadosExcell = [];

    this.areas.forEach((item: AreaAtuacao) =>{
        const varExcell: any = [];
        varExcell.push(item.CodigoAreaAtuacao);
        varExcell.push(item.NomeAreaAtuacao);
        this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
        nomeArquivo,
        Titulo,
        this.ColunasExcell,
        this.DadosExcell
      );
  }

}
