import { AutenticaService } from './../Autenticacao/autentica.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UsuarioLogado } from '../usuario/models/usuario';
import { catchError, take } from 'rxjs/operators';
import { CheckUrlAndMethodService } from './check-url-and-method.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private accountService: AutenticaService,
    private authCheckService: CheckUrlAndMethodService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let changedReq: HttpRequest<any> = req;

    if (this.authCheckService.needsAuthRequest(req.url,req.method,environment.needsAuthAplic))
    {
      let headers: HttpHeaders = req.headers
        .set('Authorization', `Bearer ${localStorage.getItem('userToken')}`)
        .set('Content-Type', 'application/json');

      changedReq = req.clone({ headers: headers });
    }

    return next.handle(changedReq);
  }
}
