import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { PlanoConta } from '../models/planoconta';
import { ExcelService } from 'src/app/excel.service';
import PlanoContaService from '../planoconta-service';


@Component({
  selector: 'app-planoconta-lista',
  templateUrl: './planoconta-lista.component.html',
  styleUrls: ['./planoconta-lista.component.css'],
})
export class PlanoContaListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private PlanoContaService: PlanoContaService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
  ) {}

  planoConta: PlanoConta[] = [];
  planoContaOriginal: PlanoConta[] = [];
  novoPlanoConta: PlanoConta = new PlanoConta();
  DadosExcell: any = [];
  ColunasExcell: any = [];
  mostrarInativos: boolean = true;
  isMinWidth: boolean = true;

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
      
    };
    this.BuscaPlanoConta();
  }

  
  BuscaPlanoConta(): void {
    this.spinner.show();
    try {
      this.PlanoContaService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.PlanoContaLista.forEach((item: any) => {
                    const tempList = new PlanoConta();
                    tempList.carregar(item);
                    this.planoContaOriginal.push(tempList);
                  });
              this.planoConta = this.planoContaOriginal 
              this.toggleMostrarInativos();
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  
  filtrarPlanoConta(){
    if (this.mostrarInativos) {
        this.planoConta = this.planoContaOriginal;
      } else {
        this.planoConta = this.planoContaOriginal.filter(item => item.Ativo);
      }
  }

  toggleMostrarInativos() {
    this.mostrarInativos = !this.mostrarInativos;
    this.filtrarPlanoConta();
  }

  Edicao(planoconta: PlanoConta) {
     this.router.navigateByUrl('/administrativo/planocontas/edicao', { state: { planoconta: planoconta, listaContaPai: this.planoContaOriginal }});
   }

  Adicionar() {
    this.spinner.show();
    this.novoPlanoConta = new PlanoConta();
    this.novoPlanoConta.IdPlanoConta = -1;
    this.spinner.hide();
    this.router.navigateByUrl('/administrativo/planocontas/edicao', {
      state: { planoconta: this.novoPlanoConta, listaContaPai: this.planoContaOriginal },
    });
   } 

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.planoContaOriginal.filter(function (d: any) {
      return (
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.planoConta = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioHistoricoCategoriaAssociado';
    var Titulo: string = 'Relatório Histórico Categorias Geral';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Categoria');
    this.ColunasExcell.push('Data Criação');
    this.ColunasExcell.push('Data Encerramento');
    this.ColunasExcell.push('Observações');
    this.ColunasExcell.push('Responsável');

    this.DadosExcell = [];
    this.planoConta.forEach((item: PlanoConta) => {
      const varExcell: any = [];
      varExcell.push(item.CodigoPlanoConta);
      varExcell.push(item.DescricaoPlanoConta);
      varExcell.push(item.TipoPlanoConta);
      varExcell.push(item.ContaPai);
      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
 }

}
