import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import PrevisaoService from '../previsao-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Previsao } from '../models/previsao';
import { PlanoConta } from '../../planoconta/models/planoconta';
import PlanoContaService from '../../planoconta/planoconta-service';

@Component({
  selector: 'app-previsao-edicao',
  templateUrl: './previsao-edicao.component.html',
  styleUrls: ['./previsao-edicao.component.css'],
})
export class PrevisaoEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  descricaoPlanoConta: string = '';
  form: FormGroup;
  dataPrevisao: Date;
  valorPrevisao: string = '';
  previsao: Previsao;
  listaPlanoConta: PlanoConta[] = [];
  planoContaOriginal: PlanoConta[] = [];
  planoContaArray: {}[];
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private previsaoService: PrevisaoService,
    private planoContaService: PlanoContaService,
    private utilsservice: UtilsService
  ) {}

  configPlanoConta = {
    search: true,
    placeholder: 'Selecione o plano de conta', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayFn:(item: any) => { return item.CodigoPlanoConta + ' - ' + item.DescricaoPlanoConta; },
    defaultValue:'IdPlanoConta',
    height: '220px',
    limitTo: 0,
    searchOnKey: 'DescricaoSelect',
    
  };

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaPlanoConta();    
    const { previsao } = window.history.state;
    const dataAtual = new Date();
    this.previsao = previsao;
    this.descricaoPlanoConta = previsao.DescricaoPlanoConta;
    this.dataPrevisao = previsao.DataPrevisao || dataAtual.getDate() ;
    this.valorPrevisao = previsao.ValorPrevisao;
    this.criarForm();

  }
  BuscaPlanoConta(): void {
    this.spinner.show();
    try {
      this.planoContaService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.PlanoContaLista.forEach((item: any) => {
                    const tempList = new PlanoConta();
                    tempList.carregar(item);
                    this.planoContaOriginal.push(tempList);
                  });

            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  SalvaPrevisao(): void {
    let requestPrevisao: Previsao = new Previsao();
    requestPrevisao.CodigoPrevisao = this.previsao.CodigoPrevisao;
    //requestPrevisao.CodigoPlanoConta = this.previsao.CodigoPlanoConta;
    requestPrevisao.CodigoPlanoConta = this.form.get("formContaPlano")?.value;
    requestPrevisao.ValorPrevisao = this.form.get("formValorPrevisao")?.value;
    requestPrevisao.DataPrevisao = this.dataPrevisao;
    requestPrevisao.Repeticao = this.form.get("formRepeticao")?.value;
    if (!requestPrevisao.CodigoPlanoConta) 
        this.messageService.showAlertDanger("Plano de Contas deve ser informado.");     
    
    else if (!requestPrevisao.ValorPrevisao) 
        this.messageService.showAlertDanger("Valor precisa ser informado.");     
    
    else if (!Previsao.saoDiferentes(requestPrevisao, this.previsao)) {
        this.messageService.showAlertDanger("Nenhum campo novo detectado para alteração.");     
        }

    else {
      this.spinner.show();
      try {
        this.previsaoService
          .SalvaPrevisao(
            requestPrevisao
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  changePlanoConta($event: any) {
    this.previsao.CodigoPlanoConta = $event.value.IdPlanoConta;
  }

  AlteraData($event: any, data: string)
  {
    this.dataPrevisao = new Date($event);
  }

  criarForm() {
    const plano = new PlanoConta
    this.planoContaArray = plano.toDict(this.planoContaOriginal)
    this.form = this.formBuilder.group({
      formDataPrevisao: [this.dataPrevisao, Validators.required],
      formValorPrevisao: [this.valorPrevisao || '', [Validators.maxLength(255)]],
      formContaPlano: [this.previsao.CodigoPlanoConta],
      formRepeticao:[{value: 1, disabled: true}]
    });
}

voltar() {
    this.router.navigateByUrl('/administrativo/previsoes');
  }
}
