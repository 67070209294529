import { Router } from '@angular/router';
import { AuthStorageService } from '../Autenticacao/auth-storage.service';
import { CheckUrlAndMethodService } from '../interceptors/check-url-and-method.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Utils } from './utils';
import { MessageService } from '../message/message.service'
@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private authStorageService: AuthStorageService,
    private checkUrlAndMethodService: CheckUrlAndMethodService
  ) {}

  showHttpError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do cliente
      this.messageService.showAlertDanger(Utils.getHttpErrorMessage(error));
    } else {
      // Erro ocorreu no lado do servidor
      let isUnauthorizedAccess = error.status === 401;

      if (isUnauthorizedAccess) {
        let isFromAplic = this.checkUrlAndMethodService.needsAuthRequest(
          error.url,
          '*',
          environment.needsAuthAplic
        );

        if (isFromAplic) {
          // Remove a autenticação do usuário.
          this.authStorageService.isLoggedInSub.next(false);

          let promise = this.router.navigate(['/login']);

          promise.then(this.showExpiredAccess.bind(this));
        } else {
          this.messageService.showAlertDanger(Utils.getHttpErrorMessage(error));
        }
      } else {
        this.messageService.showAlertDanger(Utils.getHttpErrorMessage(error));
      }
    }
  }

  // Mostra uma mensagem de sessão expirada.
  private showExpiredAccess(navigationResult: boolean) {
    if (navigationResult) {
      this.messageService.showAlertWarning('Sessão expirada, logue-se novamente.');
    }
  }

}
