export class ListaSeminarios {
  CodigoEnsinoNome: number;
  NomeEnsinoNome: string;
  TotalAssociados: number;
  TotalPartipantes: number;

  constructor() {
    this.CodigoEnsinoNome = 0;
    this.NomeEnsinoNome = '';
    this.TotalAssociados = 0;
    this.TotalPartipantes = 0;
  }

}

export class EnsinoNomeComAssociado {
  CodigoEnsinoNome: number;
  NomeEnsinoNome: string;
  TipoEnsino: string;
  TipoEnsinoDescr: string;
  ValorEnsino: number;
  NroEnsino: number;
  CodigoSocio: number;
  NomeSocio: string;
  PeriodoEnsino: string;

  constructor() {
    this.CodigoEnsinoNome = 0;
    this.NomeEnsinoNome = '';
    this.CodigoSocio = 0;
    this.NomeSocio = '';
    this.ValorEnsino = 0;
    this.NroEnsino = 0;
    this.TipoEnsino = '';
    this.TipoEnsinoDescr = '';
    this.PeriodoEnsino = '';
  }
}
