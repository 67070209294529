export class SalaOcupacao {

  CodigoSalaUso: number;
  CodigoSala: number;
  Horario: string;
  HorarioConsulta: number;
  Segunda: Ocupacao[];
  Terca: Ocupacao[];
  Quarta: Ocupacao[];
  Quinta: Ocupacao[];
  Sexta: Ocupacao[];

  constructor() {
    this.CodigoSalaUso = 0;
    this.CodigoSala = 0;
    this.Horario = '';
    this.HorarioConsulta = 0;
    this.Segunda = [];
    this.Terca = [];
    this.Quarta = [];
    this.Quinta = [];
    this.Sexta = [];
  }

}

export class Ocupacao {
  DiaSemanaConsulta: number;
  CodigoTerapeuta: number;
  NomeTerapeuta: string;
  CodigoPaciente: number;
  NomePaciente: string;

  constructor() {
    this.DiaSemanaConsulta = 0;
    this.CodigoTerapeuta = 0;
    this.NomeTerapeuta = '';
    this.CodigoPaciente = 0;
    this.NomePaciente = '';
  }
}

export class LiberaSalaParametros {
  UserName: string;
  idUsuario: number;
  CodigoSala: number;
  HorarioConsulta: number;
  DiaSemanaConsulta: number;
  CodigoTerapeuta: number;
  CodigoPaciente: number;

  constructor() {
    this.UserName = "";
    this.idUsuario = 0;
    this.CodigoSala = 0;
    this.HorarioConsulta = 0;
    this.DiaSemanaConsulta = 0;
    this.CodigoTerapeuta = 0;
    this.CodigoPaciente = 0;
  }
}

