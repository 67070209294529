export class SocioResumo {
  CodigoSocio: number;
  NomeSocio: string;
  Categoria: string;
  Categoria2: string;
  EmailSocio: string;
  AtivoSocio: boolean;
  UsuarioSocio: string;
  PerfilSocio: string;
  DataNascimentoSocio: Date;
  Telefone1Socio: string;
  Telefone2Socio: string;
  Telefone3Socio: string;
  ObservacoesSocio: string;
  EnderecoSocio: string;
  ComplementoSocio: string;
  Bairro: string;
  Cidade: string;
  UF: string;
  CepSocio: string;
  TipoEnderecoSocio: string;
  TipoEnderecoSocioDescr: string;
  AreaAtuacao: string;
  IngressoFormacaoSocio: string;
  AcoesAfirmativasSocio: boolean;
  BolsistaSocio: boolean;
  Selecionado: boolean;

  constructor() {
    this.CodigoSocio = 0;
    this.NomeSocio = '';
    this.Categoria = '';
    this.Categoria2 = '';
    this.EmailSocio = '';
    this.AtivoSocio = true;
    this.UsuarioSocio = '';
    this.PerfilSocio = '';
    //this.DataNascimentoSocio = new Date(1, 0, 1, 0, 0, 0 );
    this.Telefone1Socio = '';
    this.Telefone2Socio = '';
    this.Telefone3Socio = '';
    this.ObservacoesSocio = '';
    this.EnderecoSocio = '';
    this.ComplementoSocio = '';
    this.Bairro = '';
    this.Cidade = '';
    this.UF = '';
    this.CepSocio = '';
    this.TipoEnderecoSocio = '';
    this.TipoEnderecoSocioDescr = '';
    this.AreaAtuacao = '';
    this.IngressoFormacaoSocio = '';
    this.AcoesAfirmativasSocio = false;
    this.BolsistaSocio = false;
    this.Selecionado = false;
  }
}

export class Associado {
  CodigoSocio: number;
  NomeSocio: string;
  CodigoCategoriaOri: number;
  CodigoCategoria: number;
  Categoria: string;
  CodigoCategoria2: number;
  Categoria2: string;
  EnderecoSocio: string;
  ComplementoSocio: string;
  BairroSocio: string;
  CidadeSocio: string;
  UFSocio: string;
  CepSocio: string;
  TipoEnderecoSocio: string;
  TipoEnderecoSocioDescr: string;
  EnderecoSocio2: string;
  ComplementoSocio2: string;
  BairroSocio2: string;
  CidadeSocio2: string;
  UFSocio2: string;
  CepSocio2: string;
  TipoEnderecoSocio2: string;
  TipoEnderecoSocioDescr2: string;
  Telefone1Socio: string;
  Telefone2Socio: string;
  Telefone3Socio: string;
  DataNascimentoSocio: Date;
  CPF_CNPJSocio: string;
  AtivoSocio: boolean;
  DescontoSalaSocio: boolean;
  EmailSocio: string;
  ObservacoesSocio: string;
  UsuarioSocio: string;
  SenhaSocio: string;
  NovaSenhaSocio: string;
  ConfirmaSenhaSocio: string;
  PerfilSocio: string;
  PerfilSocioDescr: string;
  CodigoSocioEnc: number;
  NomeSocioEnc: string;
  CodigoAreaAtuacao: number;
  AreaAtuacao: string;
  NrInscrProfSocio: string;
  RGSocio: string;
  EmailAltSocio: string;
  NomeEmerSocio: string;
  TelefoneEmerSocio: string;
  DataIngressoSocio: Date;
  DataDesligamentoSocio: Date;
  DataRetornoSocio: Date;
  TipoSocio: string;
  ArqAssinaturaSocio: string;
  ArqAssinaturaSocioBase64: string;
  IngressoFormacaoSocio: string;
  AcoesAfirmativasSocio: boolean;
  BolsistaSocio: boolean;

  constructor() {
    this.CodigoSocio = 0;
    this.NomeSocio = '';
    this.CodigoCategoria = 0;
    this.CodigoCategoriaOri = 0;
    this.Categoria = '';
    this.EnderecoSocio = '';
    this.ComplementoSocio = '';
    this.BairroSocio = '';
    this.CidadeSocio = '';
    this.UFSocio = '';
    this.CepSocio = '';
    this.TipoEnderecoSocio = '';
    this.TipoEnderecoSocioDescr = '';
    this.EnderecoSocio2 = '';
    this.ComplementoSocio2 = '';
    this.BairroSocio2 = '';
    this.CidadeSocio2 = '';
    this.UFSocio2 = '';
    this.CepSocio2 = '';
    this.TipoEnderecoSocio2 = '';
    this.TipoEnderecoSocioDescr2 = '';
    this.Telefone1Socio = '';
    this.Telefone2Socio = '';
    this.Telefone3Socio = '';
    this.CPF_CNPJSocio = "";
    this.AtivoSocio = true;
    this.DescontoSalaSocio = false;
    this.EmailSocio = '';
    this.ObservacoesSocio = '';
    this.UsuarioSocio = '';
    this.SenhaSocio = '';
    this.NovaSenhaSocio = '';
    this.ConfirmaSenhaSocio = '';
    this.PerfilSocio = '';
    this.PerfilSocioDescr = '';
    this.CodigoSocioEnc = 0;
    this.NomeSocioEnc = '';
    this.CodigoAreaAtuacao = 0;
    this.AreaAtuacao = '';
    this.NrInscrProfSocio = '';
    this.RGSocio = '';
    this.EmailAltSocio = '';
    this.NomeEmerSocio = '';
    this.TelefoneEmerSocio = '';
    this.TipoSocio = '';
    this.ArqAssinaturaSocio = '';
    this.ArqAssinaturaSocioBase64 = '';
    this.IngressoFormacaoSocio = '';
    this.AcoesAfirmativasSocio = false;
    this.BolsistaSocio = false;
    // this.DataNascimentoSocio = new Date(1, 0, 1, 0, 0, 0);
    // this.DataIngressoSocio = new Date(1, 0, 1, 0, 0, 0);
    // this.DataDesligamentoSocio = new Date(1, 0, 1, 0, 0, 0);
    // this.DataRetornoSocio = new Date(1, 0, 1, 0, 0, 0);
  }
}

export class SocioDispResumo {

  CodigoSocio: number;
  NomeSocio: string;
  TipoAtendimentoSocio: string;
  DescrTipoAtendimentoSocio: string;
  LimiteAtendimentoSocio: number;
  LimiteAtendimentoSocioCri: number;
  LimiteRetIndSocio: number;
  DisponivelSocio: boolean;
  DisponivelSocioOri: boolean;
  LugarFila: number;
  LugarFilaCri: number;
  TotalAdulto: number;
  TotalCrianca: number;
  TotalHorarioExtra: number;
  TotalRetornoIndicacao: number;
  TotalOnlineAdulto: number;
  TotalOnlineCrianca: number;
  DataAfastIni: Date;
  DataAfastFim: Date;
  Supervisor: string;
  PerfilSocio: string;
  PerfilSocioDescr: string;
  PerfilSocioOri: string;
  SocioDispResHorarios: SocioDispResumoHorarios[];

  constructor() {
    this.CodigoSocio = 0;
    this.NomeSocio = '';
    this.LugarFila = 0;
    this.LugarFilaCri = 0;
    this.TotalAdulto = 0;
    this.TotalCrianca = 0;
    this.TotalHorarioExtra = 0;
    this.TotalRetornoIndicacao = 0;
    this.TotalOnlineAdulto = 0;
    this.TotalOnlineCrianca = 0;
    this.TipoAtendimentoSocio = '';
    this.DescrTipoAtendimentoSocio = '';
    this.LimiteAtendimentoSocio = 0;
    this.LimiteAtendimentoSocioCri = 0;
    this.LimiteRetIndSocio = 0;
    this.DisponivelSocio = true;
    this.DisponivelSocioOri = true;
    this.Supervisor = "";
    this.PerfilSocio = "";
    this.PerfilSocioDescr = "";
    this.PerfilSocioOri = "";
    this.SocioDispResHorarios = [];
    // this.DataAfastIni = new Date(1, 0, 1, 0, 0, 0 );
    // this.DataAfastFim = new Date(1, 0, 1, 0, 0, 0 );
  }
}

export class SocioDispResumoHorarios {
  CodigoDisponilidade: number;
  DispHora: number;
  DispSeg: number;
  DispSegConsulta: boolean;
  DispTer: number;
  DispTerConsulta: boolean;
  DispQua: number;
  DispQuaConsulta: boolean;
  DispQui: number;
  DispQuiConsulta: boolean;
  DispSex: number;
  DispSexConsulta: boolean;
  DispSegOn: number;
  DispSegConsultaOn : boolean;
  DispTerOn: number;
  DispTerConsultaOn: boolean;
  DispQuaOn: number;
  DispQuaConsultaOn: boolean;
  DispQuiOn: number;
  DispQuiConsultaOn: boolean;
  DispSexOn: number;
  DispSexConsultaOn: boolean;

  constructor() {
    this.CodigoDisponilidade = 0;
    this.DispHora = 0;
    this.DispSeg = 0;
    this.DispSegConsulta = false;
    this.DispTer = 0;
    this.DispTerConsulta = false;
    this.DispQua = 0;
    this.DispQuaConsulta = false;
    this.DispQui = 0;
    this.DispQuiConsulta = false;
    this.DispSex = 0;
    this.DispSexConsulta = false;
    this.DispSegOn = 0;
    this.DispSegConsultaOn = false;
    this.DispTerOn = 0;
    this.DispTerConsultaOn = false;
    this.DispQuaOn = 0;
    this.DispQuaConsultaOn = false;
    this.DispQuiOn = 0;
    this.DispQuiConsultaOn = false;
    this.DispSexOn = 0;
    this.DispSexConsultaOn = false;
  }
}

export class SocioMensalidade {
  CodigoMensalidade: number;
  DescricaoMensalidade: string;
  CodigoSocio: number;
  NomeAssociado: string;
  DataVencimento: Date;
  ValorTotal: number;
  ValorVencimento: number;
  DataPagamento: Date;
  ValorPagamento: number;
  GeraBoleto: string;
  Pago: number;
  BloquetoGerado: boolean;
  Tabela: string;
  Sigla: string;
  Origem: string;
  NroBoleto: string;
  CodigoEnsinoParticipante: number;
  TipoEnsino: string;
  NroBoletoBanco: string;
  TarifaVencimento: number;
  JuroVencimento: number;
  CodigoTipoLancamento: number;
  datageracao: Date;
  AtivoSocio: boolean;
  Compensado: string;
  NomeTipoLancamento: string;
  TaxaTipoLancamento: number;
  TaxaFixaTipoLancamento: number;
  CodigoCentroCusto: number;
  DescricaoPlanoConta: string;
  ValorPagamento2: number;
  CodigoTipoLancamento2: number;
  NomeTipoLancamento2: string;
  TaxaTipoLancamento2: number;
  TaxaFixaTipoLancamento2: number;
  ValorSomado: number;
  CodigoPlanoContaPai: number;
  ColunaRelatorio: string;

  constructor() {
    this.CodigoMensalidade = 0;
    this.DescricaoMensalidade = '';
    this.CodigoSocio = 0;
    this.NomeAssociado = '';
    this.ValorTotal = 0;
    this.ValorVencimento = 0;
    this.ValorPagamento = 0;
    this.GeraBoleto = '';
    this.Pago = 0;
    this.BloquetoGerado = false;
    this.Tabela = '';
    this.Origem = '';
    this.Sigla = '';
    this.NroBoleto = '';
    this.CodigoEnsinoParticipante = 0;
    this.TipoEnsino = '';
    this.NroBoletoBanco = '';
    this.TarifaVencimento = 0;
    this.JuroVencimento = 0;
    this.CodigoTipoLancamento = 0;
    this.AtivoSocio = false;
    this.Compensado = '';
    this.NomeTipoLancamento = '';
    this.TaxaTipoLancamento = 0;
    this.TaxaFixaTipoLancamento = 0;
    this.CodigoCentroCusto = 0;
    this.DescricaoPlanoConta = '';
    this.ValorPagamento2 = 0;
    this.CodigoTipoLancamento2 = 0;
    this.NomeTipoLancamento2 = '';
    this.TaxaTipoLancamento2 = 0;
    this.TaxaFixaTipoLancamento2 = 0;
    this.ValorSomado = 0;
    this.CodigoPlanoContaPai = 0;
    this.ColunaRelatorio = '';

  }
}

export class EnsinoMensalidade {
  CodigoMensalidade: number;
  CodigoEnsinoParticipante: number;
  NomeParticipante: string;
  DataVencimento: Date;
  ValorTotal: number;
  ValorVencimento: number;
  JuroVencimento: number;
  TarifaVencimento: number;
  DescontoVencimento: number;
  DataPagamento: Date;
  ValorPagamento: number;
  GeracaoAutomatica: boolean;
  DataGeracao: Date;
  BloquetoGerado: boolean;
  DescricaoMensalidade: string;
  LocalPagtoMensalidade: string;
  BoletoAvulsoMensalidade: string;
  NroBoleto: string;
  NroBoletoBanco: string;
  CodigoTipoLancamento: number;
  NomeTipoLancamento: string;
  Compensado: string;
  CompensadoDescr: string;
  bCompensado: boolean;
  ValorPagamento2: number;
  CodigoTipoLancamento2: number;
  NomeTipoLancamento2: string;
  MostrarPagto2: boolean;
  TipoMensalidade: string;
  TipoMensalidadeDescr: string;

  constructor() {
    this.CodigoMensalidade = -1;
    this.DescricaoMensalidade = '';
    this.NomeParticipante = '';
    this.ValorTotal = 0;
    this.ValorVencimento = 0;
    this.JuroVencimento = 0;
    this.TarifaVencimento = 0;
    this.DescontoVencimento = 0;
    this.ValorPagamento = 0;
    this.GeracaoAutomatica = false;
    this.LocalPagtoMensalidade = '';
    this.BoletoAvulsoMensalidade = '';
    this.NroBoleto = '';
    this.NroBoletoBanco = '';
    this.Compensado = '';
    this.CompensadoDescr = '';
    this.bCompensado = false;
    this.ValorPagamento2 = 0;
    this.BloquetoGerado = false;
    this.CodigoEnsinoParticipante = 0;
    this.TarifaVencimento = 0;
    this.JuroVencimento = 0;
    this.CodigoTipoLancamento = 1;
    this.NomeTipoLancamento = 'Dinheiro';
    this.ValorPagamento2 = 0;
    this.CodigoTipoLancamento2 = 1;
    this.NomeTipoLancamento2 = 'Dinheiro';
    this.MostrarPagto2 = false;
    this.TipoMensalidade = 'SE';
    this.TipoMensalidadeDescr = 'Mensalidade';
  }
}
