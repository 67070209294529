import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { EnsinoService } from '../../ensino.service';
import { ListaSeminarios } from '../../models/ensino';
import { SocioResumo } from '../../../associados/models/associados';

@Component({
  selector: 'app-monta-seminarios',
  templateUrl: './montaseminarios.component.html',
  styleUrls: ['./montaseminarios.component.css'],
})

export class MontaSeminariosComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private utilsservice: UtilsService,
    private ensinoService: EnsinoService
  ) {}

  ListaSeminarios: ListaSeminarios[] = [];
  ListaSeminariosOri: ListaSeminarios[] = [];
  SeminarioSel: ListaSeminarios = new ListaSeminarios();
  SeminarioSelDescr: string = "Seminário: Não selecionado";
  SociosResumo: SocioResumo[] = [];
  SociosResumoSel: SocioResumo[] = [];
  aba: string = 'S1';

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.GetListaSeminarios();
  }

  isActiveAba(aba: string): boolean {
    return this.aba == aba;
  }

  setAba(aba: string) {
    this.aba = aba;
  }

  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.ListaSeminariosOri.filter(function (d: any) {
      return (
        d.NomeEnsinoNome.toLowerCase().indexOf(val) !== -1 ||
        d.TotalPartipantes == val ||
        !val
      );
    });
    // update the rows
    this.ListaSeminarios = temp;
    return true;
  }

  GetListaSeminarios(): void {
    this.spinner.show();
    try {
      this.ensinoService
        .GetListaSeminarios()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.ListaSeminarios = this.ListaSeminariosOri = response.ListaSeminarios;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  toggleExpandRow(row: any, expanded: boolean) {
    this.SociosResumo = [];
    if (expanded == false) {
        this.ListaFaltaFazerSeminario(row.CodigoEnsinoNome)
        this.SeminarioSel = row;
        this.SeminarioSelDescr = "Seminário: " + row.NomeEnsinoNome;
    }
    else {
      this.SeminarioSel = new ListaSeminarios();
      this.SeminarioSelDescr = "Seminário: Não selecionado";
    }
    this.table.rowDetail.toggleExpandRow(row);
  }

  ListaFaltaFazerSeminario(CodigoEnsinoNome: number): void{
    this.spinner.show();
    try {
      this.ensinoService
        .ListaFaltaFazerSeminario(CodigoEnsinoNome)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.SociosResumo = response.SociosResumo;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  onSelectSeminario(row: any) {
    row.Selecionado = true;
    this.SociosResumoSel.push(row);
    return true;
  }

  removeSelectSeminario(row: any) {
    const index: number = this.SociosResumoSel.indexOf(row);
    this.SociosResumoSel.splice(index, 1);
    const indexLista: number = this.SociosResumo.indexOf(row);
    this.SociosResumo[indexLista].Selecionado  = false;
    return true;
  }

}
