import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { MessageService } from '../../../Projeto/message/message.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { CategoriaHistoricoService } from '../../categoriahistorico/categoriahistoricoservice';
import { CategoriaHistorico } from './../../categoriahistorico/models/categoriahistorico';
import { ExcelService } from 'src/app/excel.service';
import { Associado } from '../models/associados';

@Component({
  selector: 'app-associados-lista-categoria',
  templateUrl: './associados-lista-categoria.component.html',
  styleUrls: ['./associados-lista-categoria.component.css'],
})
export class AssociadosListaCategoriaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private utilsservice: UtilsService,
    private categoriaService: CategoriaHistoricoService,
    private excelService: ExcelService)
  {}

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;
  @Input() associado: Associado = new Associado();
  associadosHistorico: CategoriaHistorico[] = [];
  associadosHistoricoOri: CategoriaHistorico[] = [];
  DadosExcell: any = [];
  ColunasExcell: any = [];

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.ListaGeral();
  }

  ListaGeral(): void {
    this.spinner.show();
    try {
      this.categoriaService
        .GetListaCategoriaHistorico(this.associado.CodigoSocio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.associadosHistorico = this.associadosHistoricoOri =
                response.CategoriaHistoricoLista;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.associadosHistoricoOri.filter(function (d: any) {
      return (
        d.DescricaoCategoria.toLowerCase().indexOf(val) !== -1 ||
        d.DataCriacao.toLowerCase().indexOf(val) !== -1 ||
        d.Observacoes.toLowerCase().indexOf(val) !== -1 ||
        d.UsuarioCriador.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    // update the rows
    this.associadosHistorico = temp;
    return true;
  }

  Adicionar() {
    this.router.navigateByUrl('/cadastrogeral/edicaocateg', {
      state: { CodSocioCategoriaHist: -1 , associado: this.associado },
    });
  }


  Edicao(CodigoSocioCategoriaHist: number) {
    this.router.navigateByUrl('/cadastrogeral/edicaocateg', {
      state: { CodSocioCategoriaHist: CodigoSocioCategoriaHist , associado: this.associado },
    });
  }

  generateExcell() {
     var nomeArquivo: string = 'RelatorioHistoricoCategoriaAssociado';
     var Titulo: string = 'Relatório Histórico Categorias de ' + this.associado.NomeSocio;
     this.ColunasExcell = [];
     this.ColunasExcell.push('Categoria');
     this.ColunasExcell.push('Data Inicial');
     this.ColunasExcell.push('Data Final');
     this.ColunasExcell.push('Observações');
     this.ColunasExcell.push('Responsável');

     this.DadosExcell = [];
     this.associadosHistorico.forEach((item: CategoriaHistorico) => {
       const varExcell: any = [];
       varExcell.push(item.DescricaoCategoria);
       varExcell.push(Utils.getDateddMMyyy(item.DataCriacao));
       varExcell.push(item.DataEncerramento == null ? "" : Utils.getDateddMMyyy(item.DataEncerramento));
       varExcell.push(item.Observacoes);
       varExcell.push(item.UsuarioCriador);
       this.DadosExcell.push(varExcell);
     });
     this.excelService.generateExcel(
       nomeArquivo,
       Titulo,
       this.ColunasExcell,
       this.DadosExcell
     );
  }
}
