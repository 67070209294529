import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetValores } from './models/ret-valores'

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.RelatoriosRotinasWS}`;
  }

  ValoresDashBoard(): Observable<RetValores> {

    const url = `${this.baseURL}/ValoresDashBoard`;
    return this.httpClient.get<RetValores>(url ).pipe(
      take(1),
      map((response: RetValores) => {
        return response;
      })
    );

  }

}
