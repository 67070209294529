<app-header></app-header>

<div class="content-wrapper">
    <div class="container-fluid">
        <div class="container">
            <div class="row d-flex align-items-center justify-content-center h-100">
                <div class="col-lg-9">
                    <div class="card-title-form">Área de Atuação
                        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar"
                            placement="top" [adaptivePosition]="false">
                            <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();"><i
                                    class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
                        </span>
                    </div>

                    <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
                        <span class="col-lg-8">
                          <input type="text" #search id="search" class="form-control form-control-lg "
                            placeholder="Pesquisar  área de atuação na listagem abaixo..."
                            (keyup)="updateFilter($event)" />
                        </span>
                        <span style="margin-top: 0.5%;margin-bottom: 0.5%;" class="col-lg-4">
                          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
                          [adaptivePosition]="false" (click)="generateExcell()"><i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
                        </span>
                      </div>

                    <div class="card-body-form">
                        <ngx-datatable #myTable class="w-100 bootstrap ngx-datatable" [rows]="areas"
                            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50"
                            rowHeight="auto" [scrollbarH]="false" [scrollbarV]="false" [limit]="8"
                            [messages]="my_messages" [sorts]="[{prop: 'Ordenacao', dir: 'asc'}]">
                            <ngx-datatable-column prop="CodigoAreaAtuacao" [draggable]="false" [flexGrow]="2">
                                <ng-template ngx-datatable-header-template>
                                    <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Área de Atuação</span>
                                    <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Área de Atuação</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.CodigoAreaAtuacao}}"
                                        placement="auto" [adaptivePosition]="false">{{row.CodigoAreaAtuacao }}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column prop="NomeAreaAtuacao" [draggable]="false" [flexGrow]="6">
                                <ng-template ngx-datatable-header-template>
                                    <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                                    <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Descrição</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span containerClass="customClass" tooltip="{{row.NomeAreaAtuacao}}"
                                        placement="auto" [adaptivePosition]="false">{{row.NomeAreaAtuacao}}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Ações" [draggable]="false" [flexGrow]="1" [sortable]="false"
                            [cellClass]="'ngx-direita'" [headerClass]="'ngx-direita'">
                                <ng-template ngx-datatable-header-template>
                                    <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Ações</span>
                                    <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Ações</span>
                                </ng-template>
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span class="iconeTamanho" containerClass="customClass" tooltip="Edição"
                                        placement="top right" [adaptivePosition]="false"
                                        (click)="Edicao(row.CodigoAreaAtuacao, row.NomeAreaAtuacao); ">
                                        <i class="fa-solid fa-pen-to-square"></i></span>
                                </ng-template>
                            </ngx-datatable-column>

                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>