import { Component } from '@angular/core';
import { AutenticaService } from './Projeto/Autenticacao/autentica.service';
import { UsuarioLogado } from './Projeto/usuario/models/usuario';
import { Versao } from './Projeto/Utils/versao';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  [x: string]: any;
  constructor(
    public autenticaService: AutenticaService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.setCurrentUser();

    // Definimos os headers da página sem cache
    const headers = new HttpHeaders()
      .set('Cache-control', 'no-cache')
      .set('Pragma', 'no-cache');

    // Puxamos as informações da versão do localStorage
    console.log('Versão do localStorage: ' + localStorage.getItem('versao'));

    // Puxamos as informações da versão do servidor
    console.log('Versão atual: ' + Versao.versao);

    // Se a versão que estiver no servidor for diferente da publicada recentemente, atualiza a página limpando o cache
    if (localStorage.getItem('versao') == null) {
      localStorage.setItem('versao', Versao.versao);
    } else if (Versao.versao != localStorage.getItem('versao')) {
      localStorage.setItem('versao', Versao.versao);

      this.httpClient
        .get('', { headers, responseType: 'text' })
        .subscribe(() => location.reload());
    }
  }

  setCurrentUser(): void {
    let user: UsuarioLogado;

    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user') ?? '{}');
    } else {
      user = new UsuarioLogado();
    }

    if (user) this.autenticaService.setCurrentUser(user);
  }

  title = 'Sigsis - ' + localStorage.getItem('versao');
}
