<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Adicionar nova Previsão
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top" 
            [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">

                <div class="form-outline">
                    <label class="form-label" for="formContaPlano">Plano Conta</label>
                    <select id="formContaPlano" formControlName="formContaPlano" name="formContaPlano" class="form-select">
                        <option value="" disabled selected>Selecione...</option>
                        <option *ngFor="let conta of planoContaOriginal" [value]="conta.IdPlanoConta">{{ conta.CodigoPlanoConta }} - {{ conta.DescricaoPlanoConta }} </option>
                    </select> 
                    <!--
                 <ngx-select-dropdown formControlName="formContaPlano" 
                  (change)="changePlanoConta($event)" 
                  [config]="configPlanoConta" [options]="planoContaArray" class="form_control_ngx_select_dropdown" >
                </ngx-select-dropdown> -->
                
                <app-field-error-message [control]="form.get('formContaPlano')" label="plano de contas"></app-field-error-message>
                </div>
                <div class="row top02">
                    
                  <div class="col-lg-5">
                    <label class="form-label" style="width: 100%;">Data Previsão</label>
                    <input style="display: block"  class="form-control form-control-lg" bsDatepicker type="text"
                    (bsValueChange)="AlteraData($event , 'dataPrevisao');"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                    name="formDataPrevisao" id="formDataPrevisao" formControlName="formDataPrevisao" [value]="form.get('formDataPrevisao')">
                  </div>

                  <div class="col-lg-5">
                    <label class="form-label" for="formValorPrevisao">Valor</label>
                    <input type="text" id="formValorPrevisao" formControlName="formValorPrevisao" [value]="form.get('formValorPrevisao')"
                      name="formValorPrevisao" class="form-control form-control-lg" currencyMask/>
                    <app-field-error-message [control]="form.get('formValorPrevisao')"
                      label="Valor"></app-field-error-message>
                  </div>

                    <div class="col-lg-2">
                      <label class="form-label" for="formRepeticao">Repetições</label>
                      <input type="number" id="formRepeticao" formControlName="formRepeticao" [value]="form.get('formRepeticao')"
                        name="formRepeticao" class="form-control form-control-lg"/>
                      <app-field-error-message [control]="form.get('formRepeticao')"
                        label="Repetições"></app-field-error-message>
                    </div>
            </div>

              </div>              
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                  (click)="SalvaPrevisao();">Salvar </button>
                  <button  type="button" class="btn btn-danger"  style="cursor: not-allowed;">Excluir Lançamentos Futuros</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
