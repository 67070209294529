import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot , ActivatedRouteSnapshot, Route, CanActivate, CanLoad, UrlTree } from '@angular/router';
import { MessageService } from '../message/message.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthStorageService } from '../Autenticacao/auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    public messageService: MessageService,
    private authStorageService: AuthStorageService
  ) { }

  IsUserAuth(): Observable<boolean | UrlTree>
  {

    return this.authStorageService.isLoggedInSub
      .pipe(
        map((isLoggedIn: boolean) => {

          if (!isLoggedIn){
            this.messageService.showAlertDanger('Usuário não autenticado!');
            let login: UrlTree = this.router.parseUrl("login");

            return login;
          }

          return true;
      })

    );

  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.IsUserAuth();
  }

  canLoad(_route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.IsUserAuth();
  }

}
