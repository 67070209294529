import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { MessageService } from '../../../Projeto/message/message.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { ExcelService } from 'src/app/excel.service';
import { SocioMensalidade  } from '../models/associados';
import { AssociadosService } from '../associados.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-associados-lista-mensalidade',
  templateUrl: './associados-lista-mensalidade.component.html',
  styleUrls: ['./associados-lista-mensalidade.component.css'],
})
export class AssociadosListaMensalidadeComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private utilsservice: UtilsService,
    public modalService: BsModalService,
    private associadosService: AssociadosService,
    private excelService: ExcelService)
  {}

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;
  modalRef?: BsModalRef;
  mensalidades: SocioMensalidade[] = [];
  mensalidadesOri: SocioMensalidade[] = [];
  CodigoSocio: number = 0;
  NomeSocio: string = '';
  CodigoMensalidade: number = 0;
  DescricaoMensalidade: string = '';
  DataVencimento: Date;
  Sigla: string = '';
  DadosExcell: any = [];
  ColunasExcell: any = [];

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { CodigoSocio, NomeSocio } = window.history.state;
    this.CodigoSocio = CodigoSocio;
    this.NomeSocio = NomeSocio;
    this.ListaGeral();
  }

  ListaGeral(): void {
    this.spinner.show();
    try {
      this.associadosService
        .GetMensalidadeByCodigoSocio(this.CodigoSocio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.mensalidades = this.mensalidadesOri = response.AssociadoMensalidade;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.mensalidadesOri.filter(function (d: any) {
      return (
        d.DescricaoMensalidade.toLowerCase().indexOf(val) !== -1 ||
        d.DataVencimento.toString().toLowerCase().indexOf(val) !== -1 ||
        Utils.getDateddMMyyy(d.DataVencimento).indexOf(val) !== -1 ||
        (d.DataPagamento != null ? Utils.getDateddMMyyy(d.DataPagamento) : '').indexOf(val) !== -1 ||
        (d.ValorPagamento != null ? d.ValorPagamento.toString().toLowerCase() : '').indexOf(val) !== -1 ||
        (d.NroBoletoBanco != null ? d.NroBoletoBanco.toString().toLowerCase() : '').indexOf(val) !== -1 ||
        !val
      );
    });
    // update the rows
    this.mensalidades = temp;
    return true;
  }

  Adicionar() {
    this.router.navigateByUrl('/cadastrogeral/edicaomens', {
      state: { CodigoMensalidade: -1 , Sigla: 'SE' , CodigoSocio: this.CodigoSocio , NomeSocio: this.NomeSocio },
    });
  }

  Edicao(CodigoMensalidade: number, tpSigla: string) {
    this.router.navigateByUrl('/cadastrogeral/edicaomens', {
      state: { CodigoMensalidade: CodigoMensalidade, Sigla: tpSigla , CodigoSocio: this.CodigoSocio , NomeSocio: this.NomeSocio },
    });
  }

  generateExcell() {
     var nomeArquivo: string = 'RelatorioHistoricoMensalidadeAssociado';
     var Titulo: string = 'Relatório Histórico Mensalidades';
     this.ColunasExcell = [];
     this.ColunasExcell.push('Descrição');
     this.ColunasExcell.push('Data Vencimento');
     this.ColunasExcell.push('Valor Vencimento');
     this.ColunasExcell.push('Data Pagamento');
     this.ColunasExcell.push('Valor Pagamento');
     this.ColunasExcell.push('Número Boleto Banco');

     this.DadosExcell = [];
     this.mensalidades.forEach((item: SocioMensalidade) => {
       const varExcell: any = [];
       varExcell.push(item.DescricaoMensalidade);
       varExcell.push(Utils.getDateddMMyyy(item.DataVencimento));
       varExcell.push(item.ValorVencimento);
       varExcell.push(item.DataPagamento == null ? "" : Utils.getDateddMMyyy(item.DataPagamento));
       varExcell.push(item.ValorPagamento);
       varExcell.push(item.NroBoletoBanco);
       this.DadosExcell.push(varExcell);
     });
     this.excelService.generateExcel(
       nomeArquivo,
       Titulo,
       this.ColunasExcell,
       this.DadosExcell
     );
  }

  voltar() {
    this.router.navigateByUrl('/cadastrogeral/lista');
  }

  openModalExportar(
    templateModal: TemplateRef<any>,
    CodigoMens: number,
    descrMens: string,
    dtVencimento: Date,
    tpSigla: string
  ): void {
    this.CodigoMensalidade = CodigoMens;
    this.DescricaoMensalidade = descrMens;
    this.DataVencimento = dtVencimento;
    this.Sigla = tpSigla;
    this.modalRef = this.modalService.show(templateModal, {
      class: 'modal-dialog-centered',
    });
  }

  ExcluiMensalidadeByCodigo()
  {
    this.modalRef?.hide();
    this.spinner.show();
    try {
      this.associadosService.ExcluiMensalidadeByCodigo(this.CodigoMensalidade, this.Sigla)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            this.ListaGeral();
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  closeModal(): void {
    this.modalRef?.hide();
  }

}
