import { map } from 'rxjs/operators';
import { element } from 'protractor';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { data } from 'jquery';

const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';
@Injectable( {
        providedIn: 'root'
} )



export class ExcelService {

        constructor () {
        }

        async generateExcel ( nomeArquivo: string, titulo: string, cabecalho: any = [], dados: any = [] ) {
                //documentação em https://github.com/exceljs/exceljs
                // Excel Title, Header, Data
                const title = titulo;
                const header = cabecalho;
                const data = dados;

                // Create workbook and worksheet
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet( titulo );

                // Add Row and formatting
                const titleRow = worksheet.addRow( [ title ] );
                titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
                worksheet.addRow( [] );
                //const subTitleRow = worksheet.addRow(['Date : 06-09-2020']);
                worksheet.mergeCells( 'A1:D2' );

                // Blank Row
                worksheet.addRow( [] );

                // Add Header Row
                const headerRow = worksheet.addRow( header );

                // Cell Style : Fill and Border
                headerRow.eachCell( ( cell, number ) => {
                        cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFC0C0C0' },
                                bgColor: { argb: 'FFC0C0C0' }
                        };
                        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                } );

                // Add Data and Conditional Formatting
                data.forEach( ( d: any ) => {
                        const row = worksheet.addRow( d );
                        //const qty = row.getCell(5);
                        //let color = 'FF99FF99';
                        /*if (+qty.value < 500) {
                          color = 'FF9999';
                        }*/

                        //qty.fill = {
                        //  type: 'pattern',
                        //  pattern: 'solid',
                        //  fgColor: { argb: color }
                        //};
                }

                );

                //worksheet.getColumn(3).width = 30;
                //worksheet.getColumn(4).width = 30;
                worksheet.addRow( [] );

                // Footer Row
                //const footerRow = worksheet.addRow(['Gerada a partir dos dados de pesquisas.']);
                //footerRow.getCell(1).fill = {
                //  type: 'pattern',
                //  pattern: 'solid',
                //  fgColor: { argb: 'FFCCFFE5' }
                //};
                //footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

                // Merge Cells
                //worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

                // Salva em EXCEL
                const exensao = '.xlsx';
                workbook.xlsx.writeBuffer().then( ( data: any ) => {
                        const blob = new Blob( [ data ], {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        } );
                        fs.saveAs( blob, nomeArquivo + exensao );
                } );

                //tentativa se salvar em CSV que não rolou
                //não salva arquivo nenhum
                //const options = {
                //  dateFormat: 'DD/MM/YYYY HH:mm:ss',
                //  dateUTC: true, // use utc when rendering dates
                //  formatterOptions: {
                //    delimiter: '\t',
                //    quote: false,
                //  },
                //};
                //const exensao2 = '.csv';
                //await workbook.csv.writeFile( nomeArquivo + exensao2, options);
                //// write to a new buffer
                //const buffer = await workbook.csv.writeBuffer();

                //salva um lixo de arquivo
                //workbook.csv.writeBuffer({ formatterOptions: {  quote: true } }).then((data: any) => {
                //const blob = new Blob([data], {
                //    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                //    //type: 'text/csv'
                //    //type: 'application/octet-stream'
                //  });
                //  fs.saveAs(blob, nomeArquivo + exensao2);
                //});

        }

        private saveAsFile ( buffer: any, fileName: string, fileType: string ): void {
                const data: Blob = new Blob( [ buffer ], { type: fileType } );
                fs.saveAs( data, fileName );

        }
        /**
         * Creates an array of data to csv. It will automatically generate title row based on object keys.
         *
         * @param rows array of data to be converted to CSV.
         * @param fileName filename to save as.
         * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
         */
        public exportToCsv ( rows: object[], fileName: string, columns?: string[] ): string {

                if ( !rows || !rows.length )
                {
                        return '';
                }
                let csvContent: string[] = [];
                columns?.forEach( element => {
                        csvContent.push( element )

                } );
                csvContent.push( '\n' )

                // const separator = ',';
                // const keys = Object.keys( rows[ 0 ] ).filter( k => {
                //         if ( columns?.length )
                //         {
                //                 return columns.includes( k );
                //         } else
                //         {
                //                 return true;
                //         }
                // } );
                rows.forEach( element => {

                        if ( element == null || element == undefined )
                        {
                                csvContent.push( '' )
                                return
                        }
                        csvContent.push( element.toString() )

                        // todo verificar a quebra de linha quando o 'element/row' tem algum campo vazio;
                }
                );



                // keys.join( separator ) +
                // '\n' +
                // rows.map( row => {
                //         return keys.map( k => {

                //                 let cell: any
                //                 // row.[ k ] === null || row.[ k ] === undefined ? '' : row.[ k ];
                //                 cell = cell instanceof Date
                //                         ? cell.toLocaleString()
                //                         : cell.toString().replace( /"/g, '""' );
                //                 if ( cell.search( /("|,|\n)/g ) >= 0 )
                //                 {
                //                         cell = `"${ cell }"`;
                //                 }
                //                 return cell;
                //         } ).join( separator );
                // } ).join( '\n' );
                this.saveAsFile( csvContent, `${ fileName }${ CSV_EXTENSION }`, CSV_TYPE );
                return ''
        }

}
