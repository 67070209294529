import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';

import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Previsao } from '../models/previsao';
import { ExcelService } from 'src/app/excel.service';
import PrevisaoService from '../previsao-service';


@Component({
  selector: 'app-categoria-lista',
  templateUrl: './previsao-lista.component.html',
  styleUrls: ['./previsao-lista.component.css'],
})
export class PrevisaoListaComponent implements OnInit {
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private PrevisaoService: PrevisaoService,
    private utilsservice: UtilsService,
    private excelService: ExcelService,
  ) {}

  previsao: Previsao[] = [];
  previsaoOriginal: Previsao[] = [];
  novaPrevisao: Previsao = new Previsao();
  DadosExcell: any = [];
  ColunasExcell: any = [];

  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.BuscaPrevisao();
  }

  BuscaPrevisao(): void {
    this.spinner.show();
    try {
      this.PrevisaoService
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
                response.PrevisaoLista.forEach((item: any) => {
                    const tempList = new Previsao();
                    tempList.carregar(item);
                    this.previsaoOriginal.push(tempList);
                  });
            }
            this.previsao.sort((a: Previsao, b: Previsao) => {
                return new Date(b.DataPrevisao).getTime() - new Date(a.DataPrevisao).getTime();
            });
            this.previsao = this.previsaoOriginal;
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }
  
  Edicao(previsao: Previsao) {
     this.router.navigateByUrl('/administrativo/previsoes/edicao', { state: { previsao: previsao }});
   }

  Adicionar() {
    this.spinner.show();
    const dataAtual = new Date();
    this.novaPrevisao = new Previsao();
    //this.novaPrevisao.DataPrevisao = dataAtual.getDate();
    //this.novaPrevisao.carregar(dataAtual);
    this.novaPrevisao.CodigoPrevisao = -1;
    this.spinner.hide();
    this.router.navigateByUrl('/administrativo/previsoes/edicao', {
      state: { previsao: this.novaPrevisao },
    });
   } 

  //Rotinas - Detalhes dentro do ngx-datatable
  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.previsaoOriginal.filter(function (d: any) {
      return (
        d.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 || d.TipoPlanoConta.toLowerCase().indexOf(val) !== -1
      );
    });
    // update the rows
    this.previsao = temp;
    return true;
  }

  generateExcell() {
    var nomeArquivo: string = 'RelatorioHistoricoPrevisãoAssociado';
    var Titulo: string = 'Relatório Histórico Previsões';
    this.ColunasExcell = [];
    this.ColunasExcell.push('Plano Conta');
    this.ColunasExcell.push('Tipo');
    this.ColunasExcell.push('Data Previsão');
    this.ColunasExcell.push('Valor');
    this.DadosExcell = [];
    this.previsao.forEach((item: Previsao) => {
      const varExcell: any = [];
      varExcell.push(item.DescricaoPlanoConta);
      varExcell.push(item.TipoPlanoConta);
      varExcell.push(item.DataPrevisao);
      varExcell.push(item.ValorPrevisao);
      this.DadosExcell.push(varExcell);
    });
    this.excelService.generateExcel(
      nomeArquivo,
      Titulo,
      this.ColunasExcell,
      this.DadosExcell
    );
 }

}
