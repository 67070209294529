import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalasService } from '../../../Sistema/salas/salas.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';

@Component({
  selector: 'app-sala-edicao',
  templateUrl: './salas-edicao.component.html',
  styleUrls: ['./salas-edicao.component.css'],
})
export class SalasEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  CodigoSala: number = 0;
  form: FormGroup;
  taxaSala: number = 0;
  nomeSala: string = '';
  salaOnLine: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private salasService: SalasService,
    private utilsservice: UtilsService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { Chave } = window.history.state;
    this.CodigoSala = Chave;
    this.criarForm();
    this.GetSalaByCodigo();
  }

  GetSalaByCodigo(): void {
    this.spinner.show();
    try {
      this.salasService
        .GetSalaByCodigo(this.CodigoSala)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.nomeSala = response.Sala.NomeSala;
              this.taxaSala = response.Sala.TaxaSala;
              this.salaOnLine = response.Sala.SalaOnLine;
              this.form.controls['formNomeSala'].setValue(this.nomeSala);
              this.form.controls['formTaxaSala'].setValue(this.taxaSala);
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  SalvaSala(SalvaValorTodas: boolean): void {
    this.nomeSala = this.form.get("formNomeSala")?.value;
    this.taxaSala = this.form.get("formTaxaSala")?.value;
    if (this.nomeSala == '') {this.messageService.showAlertDanger("Nome da sala deve ser informada."); }
    else if (this.taxaSala < 0) {this.messageService.showAlertDanger("taxa deve ser informada e maior/igual a zeros."); }
    else {
      this.spinner.show();
      try {
        this.salasService
          .SalvaSala(
            this.CodigoSala,
            this.nomeSala,
            this.taxaSala,
            this.salaOnLine,
            SalvaValorTodas
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  criarForm() {
    this.form = this.formBuilder.group({
      formTaxaSala: [0, [Validators.required, Validators.min(0)]],
      formNomeSala: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  voltar() {
    this.router.navigateByUrl('/salas/cadastro');
  }
}
