<app-header></app-header>
<div class="content">
  <div class="image mb-4">
    <img src="assets/img/pagenotfound.jpeg">
  </div>
  <h1 class="tittle">Página não encontrada :/</h1>
  <h2 class="sub-tittle">Encontramos algum erro de conexão com o servidor.</h2>
  <button class="btn bg-primary mt-3" (click)="onHome()">
    Voltar para a tela inicial
  </button>
</div>
<app-footer></app-footer>
