import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CheckUrlAndMethodService } from './check-url-and-method.service';

/**
 * \brief Intercepta uma chamada HTTP para inserir a autenticação da Sispro.
 */
@Injectable({ providedIn: 'root' })
export class BaseInterceptor implements HttpInterceptor {
  constructor(private authCheckService: CheckUrlAndMethodService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let changedReq: HttpRequest<any> = req;

    if (this.authCheckService.needsAuthRequest(req.url,req.method,environment.needsBaseAuth)) {
      //Autenticação básica. --> SIGsis:SIGfreUd2023
      let headers: HttpHeaders = req.headers
      .set('Authorization', 'Basic U0lHc2lzOlNJR2ZyZVVkMjAyMw==')
      .set('Content-Type', 'application/json');

      changedReq = req.clone({ headers: headers });

    }

    return next.handle(changedReq);
  }
}
