<div class="modal-content ">
    <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="closeConfirm()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="divMessage"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirm()">{{ okText }}</button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeConfirm()">{{ cancelText }}</button>
    </div>
</div>

