import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin } from '../../usuario/models/UserLogin';
import { AutenticaService } from '../autentica.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from '../../message/message.service';
import { FormUtils } from '../../Utils/form-utils';

@Component({
  selector: 'app-Login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.css'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  model = {} as UserLogin;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private autenticaService: AutenticaService,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    this.criarForm();
  }

  criarForm() {
    this.form = this.formBuilder.group({
      formUsu: ['', [Validators.required]],
      formSenha: ['', [Validators.required]],
    });
  }

  logOn(): void {
    if (this.form.valid) {
      this.model.userName = this.form.get("formUsu")?.value;
      this.model.password = this.form.get("formSenha")?.value;
      this.spinner.show();
      this.autenticaService.login(this.model).subscribe(
        () => {
          this.spinner.hide();
          this.router.navigateByUrl('/home');
        },
        (error: any) => {
          this.spinner.hide();
          this.messageService.showAlertDanger(
            'Não foi possível validar usuário/senha. Verifique a digitação ou seu acesso a internet.'
          );
        }
      );
    } else {
      FormUtils.validateFields(this.form);
    }
  }
}
