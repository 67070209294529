import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import PlanoContaService from '../planoconta-service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { PlanoConta } from '../models/planoconta';

@Component({
  selector: 'app-planoconta-edicao',
  templateUrl: './planoconta-edicao.component.html',
  styleUrls: ['./planoconta-edicao.component.css'],
})
export class PlanoContaEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  codigoPlanoConta: string = '';
  form: FormGroup;
  nomePlanoConta: string = '';
  tipoPlanoConta: string = '';
  somarRelatorio: boolean = false;
  codigoContaPai: number = 0;
  ativo: boolean = false;
  planoConta: PlanoConta = new PlanoConta();
  contaPai: PlanoConta[] = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private planoContaService: PlanoContaService,
    private utilsservice: UtilsService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { planoconta, listaContaPai } = window.history.state;
    this.planoConta = planoconta
    this.codigoPlanoConta = this.planoConta.CodigoPlanoConta;
    this.nomePlanoConta = this.planoConta.DescricaoPlanoConta;
    this.tipoPlanoConta =  this.planoConta.TipoPlanoConta;
    this.somarRelatorio =  this.planoConta.SomaRelatorio;
    this.codigoContaPai = this.planoConta.CodigoContaPai;
    this.ativo =  this.planoConta.Ativo;
    this.contaPai = listaContaPai;
    this.criarForm();
  }

  toggleAtivo() {
    this.ativo = !this.ativo;
 }    
  toggleSomarRelatorio() {
    this.somarRelatorio = !this.somarRelatorio;
  }  

  SalvaPlanoConta(): void {
    let requestPlanoConta: PlanoConta = new PlanoConta();
    requestPlanoConta.IdPlanoConta = this.planoConta.IdPlanoConta;
    requestPlanoConta.CodigoPlanoConta = this.form.get("formCodigoPlanoConta")?.value;
    requestPlanoConta.DescricaoPlanoConta = this.form.get("formNomePlanoConta")?.value;
    requestPlanoConta.TipoPlanoConta = this.form.get("formTipoPlanoConta")?.value;
    requestPlanoConta.CodigoContaPai = this.form.get("formContaPai")?.value;
    requestPlanoConta.SomaRelatorio = this.form.get("formSomarRelatorio")?.value;
    requestPlanoConta.Ativo = this.form.get("formAtivo")?.value;

    if (requestPlanoConta.CodigoPlanoConta == '') 
        this.messageService.showAlertDanger("Plano de Contas deve ser informada.");     

    else if (requestPlanoConta.DescricaoPlanoConta == '') 
        this.messageService.showAlertDanger("Descrição do Plano de Contas deve ser informada.");     
    
    else if (!requestPlanoConta.TipoPlanoConta) 
        this.messageService.showAlertDanger("Tipo precisa ser informado.");     

    else if (!requestPlanoConta.CodigoContaPai) 
        this.messageService.showAlertDanger("Conta Pai deve ser informada.");     

    else if (!PlanoConta.saoDiferentes(requestPlanoConta, this.planoConta)) {
        this.messageService.showAlertDanger("Nenhum campo novo detectado para alteração.");     
        }

    else {
      this.spinner.show();
      try {
        this.planoContaService
          .SalvaPlanoConta(
            requestPlanoConta          
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  criarForm() {
    this.form = this.formBuilder.group({
        formCodigoPlanoConta: [this.codigoPlanoConta, [Validators.maxLength(255)]],
        formNomePlanoConta: [this.nomePlanoConta, [Validators.maxLength(255)]],
        formTipoPlanoConta: [this.tipoPlanoConta, [Validators.maxLength(255)]],
        formContaPai: [this.contaPai],
        formSomarRelatorio: [this.somarRelatorio],
        formAtivo: [this.ativo],
    });
    this.form.get('formTipoPlanoConta')?.setValue(this.tipoPlanoConta);
    this.form.get('formContaPai')?.setValue(this.codigoContaPai);
  }

  voltar() {
    this.router.navigateByUrl('/administrativo/planocontas');
  }
}
