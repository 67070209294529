import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Categoria } from './models/categoria';
import { RetCategoriaLista , RetCategoria, RetCategoriaHistoricoLista } from './models/ret-categoria';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root'
})

export class CategoriaService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.CategoriaWS}`;
  }

  GetListaGeral(): Observable<RetCategoriaLista> {

    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetCategoriaLista>(url).pipe(
      take(1),
      map((response: RetCategoriaLista) => {
        return response;
      })
    );

  }

  GetListaCategoriaHistorico(Codigosocio: number): Observable<RetCategoriaHistoricoLista> {

    const params = new HttpParams()
    .set('Codigosocio', Codigosocio)

    const url = `${this.baseURL}/ListaCategoriaHistorico`;
    return this.httpClient.get<RetCategoriaHistoricoLista>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetCategoriaHistoricoLista) => {
        return response;
      })
    );

  }

    SalvaCategoria(CodigoCategoria: number, DescricaoCategoria: string, PagaMensalidade: boolean, ValorMensalidade: number): Observable<RetError> {
        let SalvaCategoriaPar: Categoria = new Categoria;
        SalvaCategoriaPar.CodigoCategoria = CodigoCategoria;
        SalvaCategoriaPar.DescricaoCategoria = DescricaoCategoria;        
        let bodyString = JSON.stringify(SalvaCategoriaPar);

        const url = `${this.baseURL}/SalvaCategoria`;
        return this.httpClient.post<RetError>(url, bodyString ).pipe(
        take(1),
        map((response: RetError) => {
            return response;
            })
        );
    }

}
