import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoriaService } from '../../../Sistema/categoria/categoria.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';

@Component({
  selector: 'app-categoria-edicao',
  templateUrl: './categoria-edicao.component.html',
  styleUrls: ['./categoria-edicao.component.css'],
})
export class CategoriaEdicaoComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  form: FormGroup;
  codigoCategoria: number = 0;
  descricaoCategoria: string = '';
  pagaMensalidade: boolean = false;
  valorMensalidade: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private categoriaService: CategoriaService,
    private utilsservice: UtilsService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { codigo, descricao, mensalidade, valor } = window.history.state;
    this.codigoCategoria = (typeof codigo === 'number' && codigo > 0) ? codigo : -1;
    this.descricaoCategoria = (typeof descricao === 'string' && descricao.length > 0) ? descricao : "";  
    this.pagaMensalidade = (typeof mensalidade === 'boolean') ? mensalidade : false;
    this.valorMensalidade = (typeof valor === 'number' && valor > 0) ? valor : 0;
    this.criarForm(codigo);
  }

  SalvaCategoria(): void {
    this.descricaoCategoria = this.form.get("formDescricaoCategoria")?.value;
    this.pagaMensalidade = this.form.get("formPagaMensalidade")?.value;
    this.valorMensalidade = this.form.get("formValorMensalidade")?.value; 
    if (this.descricaoCategoria == '') 
        this.messageService.showAlertDanger("Descrição da Categoria deve ser informada.");   
    else if(this.pagaMensalidade && this.valorMensalidade <= 0)
        this.messageService.showAlertDanger("Valor da Mensalidade deve ser informado.");          
    else {
      this.spinner.show();
      try {
        this.categoriaService
          .SalvaCategoria(
            this.codigoCategoria,
            this.descricaoCategoria,
            this.pagaMensalidade,
            this.valorMensalidade        
          )
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  criarForm(codigo: number) {
    this.form = this.formBuilder.group({
        formDescricaoCategoria: [this.descricaoCategoria, [Validators.maxLength(255)]],
        formPagaMensalidade: [this.pagaMensalidade],
        formValorMensalidade: [this.valorMensalidade],
    });
  }

  toggleDiv() {
    this.pagaMensalidade = !this.pagaMensalidade;
  }

  voltar() {
    this.router.navigateByUrl('/administrativo/categoria');
  }
}