import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AreaAtuacao } from './models/areaatuacao';
import { RetAreaAtuacaoLista , RetAreaAtuacao } from './models/ret-areaatuacao';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root'
})

export class AreaAtuacaoService {

    private readonly baseURL: string = '';
    constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
        this.baseURL = `${environment.AreaAtuacaoWS}`;
    }

    GetListaGeral(): Observable<RetAreaAtuacaoLista> {

        const url = `${this.baseURL}/ListaGeral`;
        return this.httpClient.get<RetAreaAtuacaoLista>(url).pipe(
            take(1),
            map((response: RetAreaAtuacaoLista) => {
            return response;
            })
        );

    }
  
    SalvaAreaAtuacao(CodigoAreaAtuacao: number, NomeAreaAtuacao: string): Observable<RetError> {
        let SalvaAreaAtuacaoPar: AreaAtuacao = new AreaAtuacao;
        SalvaAreaAtuacaoPar.CodigoAreaAtuacao = CodigoAreaAtuacao || -1;
        SalvaAreaAtuacaoPar.NomeAreaAtuacao = NomeAreaAtuacao || "";       
        let bodyString = JSON.stringify(SalvaAreaAtuacaoPar);

    const url = `${this.baseURL}/SalvaAreaAtuacao`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
        take(1),
        map((response: RetError) => {
            return response;
        })
    );

    }



}
