
export interface RetArquivo {
  Error: boolean;
  ErrorMessage: string;
  RelBase64: string;
}

export class ParametrosRecord {

  NomeRelatorio: string;
  TipoRelatorio: string;
  DataInicial: Date;
  DataFinal: Date;
  CodigoIdentificacao01: number;
  CodigoIdentificacao02: number;
  CodigoIdentificacaoTxt01: string;
  CodigoIdentificacaoTxt02: string;
  Valor01: number;
  Valor02: number;
  Boleano01: boolean;
  Boleano02: boolean;

  constructor() {
    this.CodigoIdentificacao01 = 0;
    this.CodigoIdentificacao02 = 0;
    this.TipoRelatorio = '';
    this.NomeRelatorio = '';
    this.CodigoIdentificacaoTxt01 = '';
    this.CodigoIdentificacaoTxt02 = '';
    this.Valor01 = 0;
    this.Valor02 = 0;
    this.Boleano01 = false;
    this.Boleano02 = false;

  }

}
