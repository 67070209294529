import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { AssociadosService } from '../../associados/associados.service';
import { SocioResumo } from '../../associados/models/associados';

@Component({
  selector: 'app-associadotodos-pesquisa',
  templateUrl: './associadotodos-pesquisa.component.html',
})
export class AssociadoTodosPesquisaComponent implements OnInit {
  //#region Variables

  @Input() CodigoSocio: number = 0;

  @Input() CodigoSocioPreench: number = 0;
  @Input() disabled: boolean = false;

  @Output() AssociadoOut: EventEmitter<SocioResumo> =
    new EventEmitter<SocioResumo>();

  AssociadoObj: SocioResumo = new SocioResumo();
  private listaAssociadoOrigem: SocioResumo[] = [];
  listaAssociadoTela: SocioResumo[] = [];
  isLoading: boolean = true;

  my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: ' registros',
  };

  constructor(
    public bsModalService: BsModalService,
    private projectUtilservice: UtilsService,
    public service: AssociadosService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.CodigoSocioPreench != 0) {
      if (
        changes['CodigoSocioPreench']?.currentValue !=
        changes['CodigoSocioPreench']?.previousValue
      ) {
        this.GetListaAssociado();
        return;
      } else {
        this.AssociadoObj = new SocioResumo();
        return;
      }
    }

    this.AssociadoObj = new SocioResumo();
  }

  GetListaAssociado() {
    this.isLoading = true;
    this.service
      .ListaGeral(0,0,false,false)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.listaAssociadoTela = response.SociosResumo;
          this.listaAssociadoOrigem = response.SociosResumo;
          this.isLoading = false;

          if (
            this.CodigoSocioPreench != 0 &&
            this.CodigoSocioPreench &&
            this.CodigoSocioPreench !=
              this.AssociadoObj.CodigoSocio &&
            this.AssociadoObj.CodigoSocio == 0
          ) {
            this.AssociadoObj = this.listaAssociadoOrigem.find(
              (predicate) =>
                predicate.CodigoSocio == this.CodigoSocioPreench
            )!;
            this.AssociadoOut.emit(this.AssociadoObj);
          }
        },
        error: (error) => {
          this.projectUtilservice.showHttpError(error);
          this.isLoading = false;
        },
      });
  }

  limpaCampo() {
    this.AssociadoObj = new SocioResumo();
    this.CodigoSocio = 0;
    this.CodigoSocioPreench = 0;
    this.addNewItem(this.AssociadoObj);
  }

  private change($event: any) {
    this.isLoading = true;
    this.AssociadoObj.CodigoSocio = $event.CodigoSocio;
    this.AssociadoObj.NomeSocio =  $event.NomeSocio;
    this.CodigoSocio = $event.CodigoSocio;
    this.AssociadoOut.emit($event);
  }

  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.listaAssociadoOrigem.filter((elem) => {
      return elem.NomeSocio.toLowerCase().indexOf(val) !== -1 || elem.Categoria.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.listaAssociadoTela = temp;
    return true;
  }

  addNewItem(value: SocioResumo) {
    this.change(value);
    this.bsModalService.hide('AssociadoPopUpComponent');
  }

  //#endregion Rotinas formulário
}
