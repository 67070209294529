import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Payload } from '../usuario/models/payload';
import { Utils } from '../Utils/utils';
import { UsuarioLogado } from '../usuario/models/usuario';

@Injectable(
  { providedIn: 'root' }
)
export class AuthStorageService {
  private readonly __local_key = 'user_auth_portalRh';

  // indica se objeto já está marcado para salvar os dados no local storage.
  private __isSaving: boolean = false;

  /** Se ainda estamos com uma função rodando para verificar o login do usuário */
  private __isCheckingAuth: boolean = false;

  constructor()
  {
    const expectedLocalAuthStorage = localStorage.getItem(this.__local_key);

    if (expectedLocalAuthStorage) {
      const localAuthStorage = JSON.parse(expectedLocalAuthStorage) as AuthStorageService;
      this.__tokenPayload = localAuthStorage.__tokenPayload;
      this.__authToken = localAuthStorage.__authToken;
      this.__Usuario = localAuthStorage.__Usuario;
      this.startLoginCheck();
    }

  }

  public isLoggedInSub = new BehaviorSubject<boolean>(false);

  //tokenPayload
  private __tokenPayload: Payload  = new Payload();

  public get tokenPayload(): Payload {
    return this.__tokenPayload;
  }

  //authToken
  private __authToken: string = '';

  public get authToken(): string {
    return this.__authToken;
  }

  public set authToken(value: string) {
    this.__authToken = value;

    // Utiliza-se a função dos Utils para pegar o segundo valor do Token, separados por um '.'
    // e define o __tokenPayload como o objeto resultante do JSON.parse(payloadJson)
    const payloadJson: string = Utils.b64DecodeUnicode(this.__authToken.split('.')[1]);

    this.__tokenPayload = JSON.parse(payloadJson);

    this.startLoginCheck();

    this.__saveLocalInstance();
  }

  //UsuarioLogado
  private __Usuario: UsuarioLogado = new UsuarioLogado();

  public get Usuario(): UsuarioLogado {
    return this.__Usuario;
  }

  public set Usuario(value: UsuarioLogado) {
    this.__Usuario = value;

    this.__saveLocalInstance();
  }

  private async __saveLocalInstance(): Promise<void> {
    if (this.__isSaving) {
      return;
    }

    this.__isSaving = true;

    localStorage.setItem(this.__local_key, this.toJson());

    this.__notSaving();
  }

  private async __notSaving(): Promise<void> {
    this.__isSaving = false;
  }

  /**
   * Salva a instância como JSON seguro, ignorando objetos que não podem se tornar um JSON.
   */
  public toJson(): string {
    const json = `
    {
      "__authToken": "${this.authToken}",
      "__tokenPayload": ${JSON.stringify(this.tokenPayload)},
      "__Usuario": ${JSON.stringify(this.Usuario)}
     }
    `;

    return json;
  }

  /** Inicia a verificação do login. */
  public startLoginCheck() {
    if(!this.__isCheckingAuth) {
      this.reCheckLogin();
    }
  }

  /**
   * Verifica a cada diferença de tempo entre a data atual e a data do token.
   */
  public reCheckLogin() {
    if(this.__authToken === undefined || this.authToken === null) {
      this.__isCheckingAuth = false;
      return;
    }
    this.__isCheckingAuth = true;

    let nowDate: number = Math.round(Date.now() / 1000);
    if (this.tokenPayload.exp > nowDate) {
      this.isLoggedInSub.next(true);
    } else {
      // TODO: Alterar aqui para não deslogar e avisar o usuário para logar denovo.
      this.logout();

      this.__isCheckingAuth = false;
      return;
    }

    let leftTime: number = this.tokenPayload.exp - nowDate
    if (leftTime <= 0) {
      this.__isCheckingAuth = false;
      // TODO: Alterar aqui para não deslogar e avisar o usuário para logar denovo.
      this.logout();
      this.isLoggedInSub.next(false);
      return;
    }

    setTimeout(this.reCheckLogin.bind(this), leftTime * 1000);
  }

  public logout(): void {
    this.isLoggedInSub.next(false);

    this.__authToken = '';
    this.__tokenPayload = {} as Payload;
    this.__Usuario = new UsuarioLogado();

    localStorage.removeItem(this.__local_key);
  }

}
