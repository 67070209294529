import { Utils } from "src/app/Projeto/Utils/utils";


export class Previsao {
    CodigoPrevisao: number;
    CodigoPlanoConta: number;
    CodTextoPlanoConta: string;
    DescricaoPlanoConta: string;
    TipoPlanoConta: string;
    DataPrevisao: Date;
    ValorPrevisao: string;
    Repeticao: number;
    constructor() {
        this.CodigoPrevisao = 0;
        this.CodigoPlanoConta = 0;
        this.DescricaoPlanoConta = '';
        this.CodTextoPlanoConta = '';
        this.TipoPlanoConta = '';
        this.ValorPrevisao = '';
        this.Repeticao = 1;
    }
    carregar(objeto: any): void {
        this.CodigoPrevisao = objeto.CodigoPrevisao || 0;
        this.CodigoPlanoConta = objeto.CodigoPlanoConta || 0;
        this.CodTextoPlanoConta = objeto.CodTextoPlanoConta || '';
        this.DescricaoPlanoConta = objeto.DescricaoPlanoConta || '';
        this.TipoPlanoConta = objeto.TipoPlanoConta || '';
        this.DataPrevisao = Utils.getDateTimeUTC(objeto.DataPrevisao) || this.DataPrevisao.getDate();
        this.ValorPrevisao = objeto.ValorPrevisao || '';
    }

    converterDataPrevisao(): string {
        const data = new Date(this.DataPrevisao);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;
    } 
    converterValorPrevisao(): string {
        const valor = parseFloat(this.ValorPrevisao);
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    toJson(): any {
        return {
            CodigoPrevisao: this.CodigoPrevisao,
            CodigoPlanoConta: this.CodigoPlanoConta,
            ValorPrevisao: this.ValorPrevisao,
            DataPrevisao: this.DataPrevisao.toISOString(),
            Repeticao: this.Repeticao,
            
        };
    }

    static saoDiferentes(previsao1: Previsao, previsao2: Previsao): boolean {
        return previsao1.CodigoPrevisao !== previsao2.CodigoPrevisao ||
               previsao1.CodigoPlanoConta !== previsao2.CodigoPlanoConta ||
               previsao1.CodTextoPlanoConta !== previsao2.CodTextoPlanoConta ||
               previsao1.DescricaoPlanoConta !== previsao2.DescricaoPlanoConta ||
               previsao1.TipoPlanoConta !== previsao2.TipoPlanoConta ||
               previsao1.ValorPrevisao !== previsao2.ValorPrevisao ||
               previsao1.Repeticao !== previsao2.Repeticao;
    }

}  
