<div class="content-wrapper">
  <div class="container-fluid">

    <div>
      <div class="card-title-form-menor-FB">Histórico das Categorias
        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar" placement="top"
          [adaptivePosition]="false">
          <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();"><i
            class="fa-solid fa-plus icone-para-botao" ></i>Adicionar</button>
        </span>
        <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
        [adaptivePosition]="false" (click)="generateExcell()"><i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
      </div>

      <div class="card-body-form">
        <div class="table-responsive table-responsive-overflow-450">
          <ngx-datatable #myTable class="bootstrap ngx-datatable" [rows]="associadosHistorico"
            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
            [scrollbarH]="false" [scrollbarV]="false" [limit]="6" [messages]="my_messages">

            <ngx-datatable-column prop="DescricaoCategoria" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Categoria</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Categoria</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.DescricaoCategoria}}" placement="auto"
                  [adaptivePosition]="false">{{row.DescricaoCategoria}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="DataCriacao" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Data Alteração</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Data Alteração</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.DataCriacao}}" placement="auto"
                  [adaptivePosition]="false">{{row.DataCriacao | date: 'dd/MM/yyyy'}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="DataEncerramento" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Data Encerramento</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Data Encerramento</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.DataEncerramento}}" placement="auto"
                  [adaptivePosition]="false">{{row.DataEncerramento | date: 'dd/MM/yyyy'}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="Observacoes" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Observações</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Observações</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.Observacoes}}" placement="auto"
                  [adaptivePosition]="false">{{row.Observacoes}}</span>
              </ng-template>
            </ngx-datatable-column>

            <!-- <ngx-datatable-column prop="UsuarioCriador" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Responsável</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Responsável</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.UsuarioCriador}}" placement="auto"
                  [adaptivePosition]="false">{{row.UsuarioCriador}}</span>
              </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column name="Ações" [sortable]="false" [cellClass]="'ngx-direita'"
              [headerClass]="'ngx-direita'" [draggable]="false" [flexGrow]="1">
              <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top"
                  [adaptivePosition]="false" (click)="Edicao(row.CodigoSocioCategoriaHist); ">
                  <i class="fa-regular fa-pen-to-square"></i></span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>

