<!DOCTYPE html>
<div
  class="alert alert-{{
    type
  }} w-100 no-margin d-inline-block top-10 start-50 translate-middle-x d-flex justify-content-between"
  role="alert"
>
  <div id="divMessage"></div>
  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="closeAlert()"
    ></button>
  </div>
</div>
