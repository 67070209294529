import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalasService } from '../../../Sistema/salas/salas.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning';
import { SalaOcupacao } from '../models/salaOcupacao';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sala-ocupacao-exibe',
  templateUrl: './salas-ocupacao-exibe.component.html',
  styleUrls: ['./salas-ocupacao-exibe.component.css'],
})
export class SalasOcupacaoExibeComponent implements OnInit {
  [x: string]: any;
  constructor(
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private salasService: SalasService,
    public modalService: BsModalService,
    private utilsservice: UtilsService
  ) {}
  modalRef?: BsModalRef;
  salasOcu: SalaOcupacao[] = [];
  @Input() CodigoSala: number = 0;
  //Variaveis - Detalhes dentro do ngx-datatable
  @ViewChild('myTable') table: any;
  nomeTerapeuta: string = '';
  nomePaciente: string = '';
  HoraConsulta: number = 0;
  DiaSemanaConsulta: number = 0;
  CodigoTerapeuta: number = 0;
  CodigoPaciente: number = 0;

  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public rightPosition: AvailbleBSPositions = 'right';
  //#endregion Variables

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.GetOcupacaoSala();
  }

  GetOcupacaoSala() {
    this.spinner.show();
    try {
      this.salasService
        .GetOcupacaoSala(this.CodigoSala)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.salasOcu = response.SalaOcupacao;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  LiberaSala() {
    this.modalRef?.hide();
    this.spinner.show();
    try {
      this.salasService
        .LiberaSala(
          this.CodigoSala,
          this.HoraConsulta,
          this.DiaSemanaConsulta,
          this.CodigoTerapeuta,
          this.CodigoPaciente
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.salasOcu = response.SalaOcupacao;
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  openModalExportar(
    templateModal: TemplateRef<any>,
    CodSala: number,
    nomeTera: string,
    nomePaci: string,
    Hora: number,
    DiaSemana: number,
    CodigoTera: number,
    CodigoPaci: number
  ): void {
    this.CodigoSala = CodSala;
    this.nomeTerapeuta = nomeTera;
    this.nomePaciente = nomePaci;
    this.HoraConsulta = Hora;
    this.DiaSemanaConsulta = DiaSemana;
    this.CodigoTerapeuta = CodigoTera;
    this.CodigoPaciente = CodigoPaci;

    this.modalRef = this.modalService.show(templateModal, {
      class: 'modal-dialog-centered',
    });
  }

  closeModal(): void {
    this.modalRef?.hide();
  }
}
