<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Adicionar novo Plano de Contas
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">

                <div class="form-outline">
                  <label class="form-label" for="formCodigoPlanoConta">Plano Contas</label>
                  <input type="text" id="formCodigoPlanoConta" formControlName="formCodigoPlanoConta" [value]="form.get('formCodigoPlanoConta')"
                    name="formCodigoPlanoConta" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formCodigoPlanoConta')"
                    label="Código do plano de contas"></app-field-error-message>
                </div>

                <div class="form-outline">
                    <label class="form-label" for="formNomePlanoConta">Descrição</label>
                    <input type="text" id="formNomePlanoConta" formControlName="formNomePlanoConta" [value]="form.get('formNomePlanoConta')"
                      name="formNomePlanoConta" class="form-control form-control-lg" />
                    <app-field-error-message [control]="form.get('formNomePlanoConta')"
                      label="descrição do plano de contas"></app-field-error-message>
                  </div>

                  <div class="form-outline">
                    <label class="form-label" for="formTipoPlanoConta">Tipo</label>
                    <select id="formTipoPlanoConta" formControlName="formTipoPlanoConta" name="formTipoPlanoConta" class="form-select">
                      <option value="" disabled selected>Selecione...</option> <!-- Opção padrão -->
                      <option value="Receita">Receita</option>
                      <option value="Despesa">Despesa</option>
                    </select>
                    <app-field-error-message [control]="form.get('formTipoPlanoConta')" label="tipo do plano de contas"></app-field-error-message>
                  </div>
                  

                  <div class="form-outline">
                    <label class="form-label" for="formContaPai">Conta Pai</label>
                    <select id="formTipoPlanoConta" formControlName="formContaPai" name="formContaPai" class="form-select">
                        <option value="" disabled selected>Selecione...</option> <!-- Opção padrão -->
                        <option *ngFor="let conta of contaPai" [value]="conta.IdPlanoConta">{{ conta.CodigoPlanoConta }} - {{ conta.DescricaoPlanoConta }}</option>
                    </select>
                    <app-field-error-message [control]="form.get('formContaPai')" label="tipo do plano de contas"></app-field-error-message>
                  </div>

                  <div class="form-outline">
                    <label class="form-check-label" for="formSomarRelatorio">Soma Relatório Exercício </label>
                    <span class="d-inline"> <ui-switch name="formSomarRelatorio" id="formSomarRelatorio" formControlName="formSomarRelatorio"
                        (click)="toggleSomarRelatorio();" size="medium"></ui-switch></span>   
                    <app-field-error-message [control]="form.get('formSomarRelatorio')"
                      label="Somar relatórios"></app-field-error-message>
                    </div>
                    
                    <div class="form-outline">
                    <label class="form-check-label" for="formAtivo">Ativo </label>
                    <!--<input type="checkbox" id="formAtivo" formControlName="formAtivo" [value]="form.get('formAtivo')"
                      name="formAtivo" class="form-check-input" /> -->
                     <span class="d-inline"> <ui-switch name="formAtivo" id="formAtivo" formControlName="formAtivo"
                          (click)="toggleAtivo();" size="medium"></ui-switch></span> 
                    <app-field-error-message [control]="form.get('formAtivo')"
                      label="Somar relatórios"></app-field-error-message>
                  </div>

              </div>              
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                  (click)="SalvaPlanoConta();">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
