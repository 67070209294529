import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserLogin } from '../usuario/models/UserLogin';
import { RetLogin } from '../usuario/models/ret-login';
import { UsuarioLogado } from '../usuario/models/usuario';
import { AuthStorageService } from './auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AutenticaService {
  private currentUserSource = new ReplaySubject<UsuarioLogado>(1);
  public currentUser$ = this.currentUserSource.asObservable();

  private readonly baseURL: string = '';
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private authStorageService: AuthStorageService
  ) {
    this.baseURL = `${environment.UsuariosWS}`;
  }

  login(parmsLogin: UserLogin): Observable<void> {
    let bodyString = JSON.stringify(parmsLogin);

    const url = `${this.baseURL}/ValidarLogin`;
    return this.httpClient.post<RetLogin>(url, bodyString).pipe(
      take(1),
      map((response: RetLogin) => {
        const user = response.DadosUsuario;
        if (user) {
          this.setCurrentUser(user);
        }
        this.authStorageService.isLoggedInSub.next(true);
        this.authStorageService.authToken = response.DadosUsuario.Token;
        this.authStorageService.Usuario = response.DadosUsuario;

      })
    );
  }

  logout(): void {

    this.authStorageService.logout();

    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    this.currentUserSource.next(new UsuarioLogado);
    this.currentUserSource.complete();

    this.router.navigate(['/login']);
  }

  public setCurrentUser(user: UsuarioLogado): void {
    localStorage.setItem('userToken', user.Token);
    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSource.next(user);
  }

}
