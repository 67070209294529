import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetCategoriaHistoricoLista, RetCategoriaHistorico } from './models/ret-categoriahistorico';

import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { CategoriaHistorico } from './models/categoriahistorico';

@Injectable({
  providedIn: 'root'
})

export class CategoriaHistoricoService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.CategoriaWS}`;
  }

  GetListaCategoriaHistorico(Codigosocio: number): Observable<RetCategoriaHistoricoLista> {

    const params = new HttpParams()
    .set('Codigosocio', Codigosocio)

    const url = `${this.baseURL}/ListaCategoriaHistorico`;
    return this.httpClient.get<RetCategoriaHistoricoLista>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetCategoriaHistoricoLista) => {
        return response;
      })
    );

  }

  BuscaCategoriaHistorico(CodigoSocioCategoriaHist: number): Observable<RetCategoriaHistorico> {

    const params = new HttpParams()
    .set('CodigoSocioCategoriaHist', CodigoSocioCategoriaHist)

    const url = `${this.baseURL}/BuscaCategoriaHistorico`;
    return this.httpClient.get<RetCategoriaHistorico>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetCategoriaHistorico) => {
        return response;
      })
    );

  }

  SalvaCategoriaHistorico(CategoriaHist: CategoriaHistorico): Observable<RetError> {

    let bodyString = JSON.stringify(CategoriaHist);

    const url = `${this.baseURL}/SalvaCategoriaHistorico`;
    return this.httpClient.post<RetError>(url, bodyString ).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }


}
