import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
  } from '@angular/core';
  import { BsModalService } from 'ngx-bootstrap/modal';
  import { take } from 'rxjs';
  import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
  import { PlanoConta } from '../../planoconta/models/planoconta';
  import  PlanoContaService  from '../../planoconta/planoconta-service';
  
  @Component({
    selector: 'app-PlanoConta-pesquisa',
    templateUrl: './PlanoConta-pesquisa.component.html',
  })
  export class PlanoContaPesquisaComponent implements OnInit {
    //#region Variables
  
    @Input() CodigoPlanoConta: string = '';
  
    @Input() CodigoPlanoContaPreench: string = '0';
    @Input() disabled: boolean = false;
  
    @Output() PlanoContaOut: EventEmitter<PlanoConta> =
      new EventEmitter<PlanoConta>();
  
    PlanoContaObj: PlanoConta = new PlanoConta();
    private listaPlanoContaOrigem: PlanoConta[] = [];
    listaPlanoContaTela: PlanoConta[] = [];
    isLoading: boolean = true;
  
    my_messages = {
      emptyMessage: 'Consulta não retornou registros.',
      totalMessage: ' registros',
    };
  
    constructor(
      public bsModalService: BsModalService,
      private projectUtilservice: UtilsService,
      public service: PlanoContaService
    ) {}
  
    ngOnInit(): void {}
  
    ngOnChanges(changes: SimpleChanges): void {
      if (this.CodigoPlanoContaPreench != '') {
        if (
          changes['CodigoPlanoContaPreench']?.currentValue !=
          changes['CodigoPlanoContaPreench']?.previousValue
        ) {
          this.GetListaGeral();
          return;
        } else {
          this.PlanoContaObj = new PlanoConta();
          return;
        }
      }
  
      this.PlanoContaObj = new PlanoConta();
    }
  
    GetListaGeral() {
      this.isLoading = true;
      this.service
        .GetListaGeral()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.listaPlanoContaTela = response.PlanoContaLista;
            this.listaPlanoContaOrigem = response.PlanoContaLista;
            this.isLoading = false;
  
            if (
              this.CodigoPlanoContaPreench != '' &&
              this.CodigoPlanoContaPreench &&
              this.CodigoPlanoContaPreench !=
              this.PlanoContaObj.CodigoPlanoConta &&
              this.PlanoContaObj.CodigoPlanoConta == ''
            ) {
              this.PlanoContaObj = this.listaPlanoContaOrigem.find(
                (predicate) =>
                  predicate.CodigoPlanoConta == this.CodigoPlanoContaPreench
              )!;
              this.PlanoContaOut.emit(this.PlanoContaObj);
            }
          },
          error: (error) => {
            this.projectUtilservice.showHttpError(error);
            this.isLoading = false;
          },
        });
    }
  
    limpaCampo() {
      this.PlanoContaObj = new PlanoConta();
      this.CodigoPlanoConta = '';
      this.CodigoPlanoContaPreench = '';
      this.addNewItem(this.PlanoContaObj);
    }
  
    private change($event: any) {
      this.isLoading = true;
      this.PlanoContaObj.IdPlanoConta = $event.CodigoPlanoConta;
      this.PlanoContaObj.CodigoPlanoConta = $event.CodTextoPlanoConta;
      this.PlanoContaObj.DescricaoPlanoConta =  $event.DescricaoPlanoConta;
      this.PlanoContaObj.TipoPlanoConta =  $event.TipoPlanoConta;
      this.PlanoContaObj.ContaPai =  $event.ContaPai;
      this.CodigoPlanoConta = $event.CodTextoPlanoConta;
      this.PlanoContaOut.emit($event);
    }
  
    updateFilter($event: any) {
      const val = $event.target.value.toLowerCase();
      // filter our data
      const temp = this.listaPlanoContaOrigem.filter((elem) => {
        return elem.DescricaoPlanoConta.toLowerCase().indexOf(val) !== -1 || !val;
      });
  
      // update the rows
      this.listaPlanoContaTela = temp;
      return true;
    }
  
    addNewItem(value: PlanoConta) {
      this.change(value);
      this.bsModalService.hide('PlanoContaPopUpComponent');
    }
  
  
  }
  