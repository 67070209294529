import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetArquivo, ParametrosRecord } from '../Relatorios/models/ret-relatorio';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';

@Injectable({
  providedIn: 'root'
})

export class RotinasService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.RelatoriosRotinasWS}`;
  }

  ExecutaRotina(ParRel: ParametrosRecord): Observable<RetArquivo> {

    let bodyString = JSON.stringify(ParRel);

    const url = `${this.baseURL}/ExecutaRotina`;
    return this.httpClient.post<RetArquivo>(url, bodyString ).pipe(
      take(1),
      map((response: RetArquivo) => {
        return response;
      })
    );

  }

}
