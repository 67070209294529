import { Component, Input, OnInit  } from '@angular/core';
import { FormGroup,  Validators, FormBuilder } from '@angular/forms';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssociadoEdicaoService } from '../associadosedicao.service';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Lista } from '../../models-gerais/lista';
import { AreaAtuacao } from '../../areaatuacao/models/areaatuacao';
import { Categoria } from '../../categoria/models/categoria';
import { ListasGeraisService } from '../../listasgerais/listasgerais.service';
import { Associado } from '../models/associados';

@Component({
  selector: 'app-associados-edicao-prio',
  templateUrl: './associados-edicao-prio.component.html',
  styleUrls: ['./associados-edicao-prio.component.css']

})

export class AssociadosEdicaoPrioComponent implements OnInit {

  constructor(
    public frmBui: FormBuilder,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    public associadosEdicao: AssociadoEdicaoService,
    private utilsservice: UtilsService,
    private listasGeraisService: ListasGeraisService
  ) {

  }

  form: FormGroup;

  Perfil: Lista = new Lista();
  configPerfil = {
    search: true,
    placeholder: 'Selecione o perfil', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO
    height: '220px',
    limitTo: 0,
  };

  mensagem: string = '';
  @Input() associado: Associado = new Associado();
  AreaAtuacaoObj: AreaAtuacao = new AreaAtuacao();
  CategoriaObj: Categoria = new Categoria();
  CategoriaObj2: Categoria = new Categoria();

  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.criarForm();

    this.form.controls['NomeSocio'].setValue(this.associado.NomeSocio);
    this.form.controls['DataNascimentoSocio'].setValue(this.associado.DataNascimentoSocio);
    this.form.controls['NrInscrProfSocio'].setValue(this.associado.NrInscrProfSocio);
    this.form.controls['CPF_CNPJSocio'].setValue(this.associado.CPF_CNPJSocio);
    this.form.controls['RGSocio'].setValue(this.associado.RGSocio);
    this.form.controls['EmailSocio'].setValue(this.associado.EmailSocio);
    this.form.controls['EmailAltSocio'].setValue(this.associado.EmailAltSocio);
    this.form.controls['Telefone1Socio'].setValue(this.associado.Telefone1Socio);
    this.form.controls['Telefone2Socio'].setValue(this.associado.Telefone2Socio);
    this.form.controls['Telefone3Socio'].setValue(this.associado.Telefone3Socio);
    this.form.controls['UsuarioSocio'].setValue(this.associado.UsuarioSocio);
    this.form.controls['DataDesligamentoSocio'].setValue(this.associado.DataDesligamentoSocio);
    this.form.controls['DataRetornoSocio'].setValue(this.associado.DataRetornoSocio);
    this.form.controls['NomeEmerSocio'].setValue(this.associado.NomeEmerSocio);
    this.form.controls['TelefoneEmerSocio'].setValue(this.associado.TelefoneEmerSocio);
    this.form.controls['ObservacoesSocio'].setValue(this.associado.ObservacoesSocio);
    this.form.controls['IngressoFormacaoSocio'].setValue(this.associado.IngressoFormacaoSocio);

    this.GetListasFixas();
  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('Perfil');

    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.Perfil = response.ListasFixas[0];
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  AlteraData($event: any,data: string)
  {
    if (data == 'DataNascimentoSocio')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.associado.DataNascimentoSocio = new Date($event);
      this.associadosEdicao.EdicaoPrio.controls['DataNascimentoSocio'].setValue(this.associado.DataNascimentoSocio);
    }
    else if (data == 'DataIngressoSocio')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.associado.DataIngressoSocio = new Date($event);
      this.associadosEdicao.EdicaoPrio.controls['DataIngressoSocio'].setValue(this.associado.DataIngressoSocio);
    }
    else if (data == 'DataDesligamentoSocio')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.associado.DataDesligamentoSocio = new Date($event);
      this.associadosEdicao.EdicaoPrio.controls['DataDesligamentoSocio'].setValue(this.associado.DataDesligamentoSocio);
    }
    else if (data == 'DataRetornoSocio')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.associado.DataRetornoSocio = new Date($event);
      this.associadosEdicao.EdicaoPrio.controls['DataRetornoSocio'].setValue(this.associado.DataRetornoSocio);
    }

  }

  criarForm() {
    this.form  = this.frmBui.group({
      NomeSocio: ['', [Validators.required, Validators.maxLength(80)] ],
      DataNascimentoSocio: [''],
      AtivoSocio: [this.associado.AtivoSocio],
      DescontoSalaSocio: [this.associado.DescontoSalaSocio],
      PerfilSocioDescr: [this.associado.PerfilSocioDescr],
      CodigoCategoria: [this.associado.CodigoCategoria],
      CodigoAreaAtuacao: [this.associado.CodigoAreaAtuacao],
      NrInscrProfSocio: ['', [Validators.maxLength(50)] ],
      CPF_CNPJSocio: ['', [Validators.maxLength(20)]  ],
      RGSocio: ['', [Validators.maxLength(20)] ],
      EmailSocio: ['', [Validators.maxLength(100)]  ],
      EmailAltSocio: ['', [Validators.maxLength(100)] ],
      Telefone1Socio: ['', [Validators.maxLength(15)] ],
      Telefone2Socio: ['', [Validators.maxLength(15)] ],
      Telefone3Socio: ['', [Validators.maxLength(15)] ],
      UsuarioSocio: ['', [Validators.maxLength(30)]  ],
      SenhaSocio: ['', [Validators.maxLength(32)]  ],
      NovaSenhaSocio: ['', [Validators.maxLength(32)]  ],
      ConfirmaSenhaSocio: ['', [Validators.maxLength(32)]  ],
      IngressoFormacaoSocio: [''],
      DataDesligamentoSocio: [''],
      DataRetornoSocio: [''],
      NomeEmerSocio: ['', [Validators.maxLength(80)] ],
      TelefoneEmerSocio: ['', [Validators.maxLength(15)] ],
      ObservacoesSocio: [''],
    })

  }

  changeNome() {
    this.associado.NomeSocio = this.form.get('NomeSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['NomeSocio'].setValue(this.form.get('NomeSocio')?.value);
  }

  changeAtivo($event: any) {
    this.associado.AtivoSocio = $event;
    this.associadosEdicao.EdicaoPrio.controls['AtivoSocio'].setValue($event);
  }

  changeDesconto($event: any) {
    this.associado.DescontoSalaSocio = $event;
    this.associadosEdicao.EdicaoPrio.controls['DescontoSalaSocio'].setValue($event);
  }

  changeBolsista($event: any) {
    this.associado.BolsistaSocio = $event;
    this.associadosEdicao.EdicaoPrio.controls['BolsistaSocio'].setValue($event);
  }

  changeAcoesAfirmativas($event: any) {
    this.associado.AcoesAfirmativasSocio = $event;
    this.associadosEdicao.EdicaoPrio.controls['AcoesAfirmativasSocio'].setValue($event);
  }

  changeArea($eventArea: AreaAtuacao) {
    this.AreaAtuacaoObj = $eventArea;
    this.associado.CodigoAreaAtuacao = this.AreaAtuacaoObj.CodigoAreaAtuacao;
    this.associadosEdicao.EdicaoPrio.controls['CodigoAreaAtuacao'].setValue(this.AreaAtuacaoObj.CodigoAreaAtuacao);
  }

  changeCategoria($eventCategoria: Categoria) {
    this.CategoriaObj = $eventCategoria;
    this.associado.CodigoCategoria = this.CategoriaObj.CodigoCategoria;
    this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria'].setValue(this.CategoriaObj.CodigoCategoria);
  }

  changeCategoria2($eventCategoria: Categoria) {
    this.CategoriaObj2 = $eventCategoria;
    this.associado.CodigoCategoria2 = this.CategoriaObj2.CodigoCategoria;
    this.associadosEdicao.EdicaoPrio.controls['CodigoCategoria2'].setValue(this.CategoriaObj2.CodigoCategoria);
  }

  changePerfil($event: any) {
    this.associado.PerfilSocio = $event.value.Valor;
    this.associadosEdicao.EdicaoPrio.controls['PerfilSocio'].setValue($event.value.Valor);
    this.associado.PerfilSocioDescr = $event.value.Descricao;
  }

  changeNI() {
    this.associado.NrInscrProfSocio = this.form.get('NrInscrProfSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['NrInscrProfSocio'].setValue(this.form.get('NrInscrProfSocio')?.value);
  }

  changeCPFCNPJ() {

    if(this.form.get('CPF_CNPJSocio')?.value.length == 11)
    {
      if ( !this.isValidCPF(this.form.get('CPF_CNPJSocio')?.value) )
      {
        this.messageService.showAlertDanger("CPF inválido");
        return false;
      }
    }
    else if(this.form.get('CPF_CNPJSocio')?.value.length == 14)
    {
      if ( !this.ValidaCamposCNPJ(this.form.get('CPF_CNPJSocio')?.value) )
      {
        this.messageService.showAlertDanger("CNPJ inválido");
        return false;
      }
    }
    else
    {
      this.messageService.showAlertDanger("CPF ou CPNJ com quantidade de caracteres inválida");
      return false;
    }

    this.associado.CPF_CNPJSocio = this.form.get('CPF_CNPJSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['CPF_CNPJSocio'].setValue(this.form.get('CPF_CNPJSocio')?.value);
    return true;
  }

  changeIngressoFormacaoSocio() {
    this.associado.IngressoFormacaoSocio = this.form.get('IngressoFormacaoSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['IngressoFormacaoSocio'].setValue(this.form.get('IngressoFormacaoSocio')?.value);
  }

  isValidCPF(cpf: string) {
    // Validar se é String
    if (typeof cpf !== 'string') return false

    // Tirar formatação
    cpf = cpf.replace(/[^\d]+/g, '')

    // Validar se tem tamanho 11 ou se é uma sequência de digitos repetidos
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false

    // String para Array
    let cpfs: string[] = cpf.split('')

    const validator = cpfs
        // Pegar os últimos 2 digitos de validação
        .filter((digit, index, array) => index >= array.length - 2 && digit)
        // Transformar digitos em números
        .map( el => +el )

    const toValidate = (pop: number) => cpfs
        // Pegar Array de items para validar
        .filter((digit, index, array) => index < array.length - pop && digit)
        // Transformar digitos em números
        .map(el => +el)

    const rest = (count: number, pop: number) => (toValidate(pop)
        // Calcular Soma dos digitos e multiplicar por 10
        .reduce((soma, el, i) => soma + el * (count - i), 0) * 10)
        // Pegar o resto por 11
        % 11
        // transformar de 10 para 0
        % 10

    return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
  }

  ValidaCamposCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[^\d]+/g,'');
    // Validar se tem tamanho 14 ou se é uma sequência de digitos repetidos
    if (cnpj.length !== 14 || !!cnpj.match(/(\d)\1{13}/)) return false

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado.toString() != digitos.charAt(1)) return false;
    return true;
  }

  changeRG() {
    this.associado.RGSocio = this.form.get('RGSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['RGSocio'].setValue(this.form.get('RGSocio')?.value);
  }

  validarEmail(email: any, tipo: string): boolean{

    if (email.target.value == undefined || email.target.value == "")
    {
      if (tipo == "P")
      {
        this.associado.EmailSocio = "";
        this.associadosEdicao.EdicaoPrio.controls['EmailSocio'].setValue("");
      }
      else
      {
        this.associado.EmailAltSocio = "";
        this.associadosEdicao.EdicaoPrio.controls['EmailAltSocio'].setValue("");
      }
      return true;
    }

    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(email.target.value); // true
    if (result) {
      if (tipo == "P")
      {
        this.associado.EmailSocio = email.target.value;
        this.associadosEdicao.EdicaoPrio.controls['EmailSocio'].setValue(email.target.value);
      }
      else
      {
        this.associado.EmailAltSocio = email.target.value;
        this.associadosEdicao.EdicaoPrio.controls['EmailAltSocio'].setValue(email.target.value);
      }
    }
    else {
      if (tipo == "P")
      { this.messageService.showAlertDanger("E-mail principal inválido"); }
      else
      { this.messageService.showAlertDanger("E-mail alternativo inválido"); }
    }
    return result;
  }

  changeFone1() {
    this.associado.Telefone1Socio = this.form.get('Telefone1Socio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['Telefone1Socio'].setValue(this.form.get('Telefone1Socio')?.value);
  }

  changeFone2() {
    this.associado.Telefone2Socio = this.form.get('Telefone2Socio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['Telefone2Socio'].setValue(this.form.get('Telefone2Socio')?.value);
  }

  changeFone3() {
    this.associado.Telefone3Socio = this.form.get('Telefone3Socio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['Telefone3Socio'].setValue(this.form.get('Telefone3Socio')?.value);
  }

  changeUsuario() {
    this.associado.UsuarioSocio = this.form.get('UsuarioSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['UsuarioSocio'].setValue(this.form.get('UsuarioSocio')?.value);
  }

  changeSenha() {
    this.associado.SenhaSocio = this.form.get('SenhaSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['SenhaSocio'].setValue(this.form.get('SenhaSocio')?.value);
  }

  changeNovaSenha() {
    this.associado.NovaSenhaSocio = this.form.get('NovaSenhaSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['NovaSenhaSocio'].setValue(this.form.get('NovaSenhaSocio')?.value);
  }

  changeConfirmaSenha() {
    this.associado.ConfirmaSenhaSocio = this.form.get('ConfirmaSenhaSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['ConfirmaSenhaSocio'].setValue(this.form.get('ConfirmaSenhaSocio')?.value);
  }

  changeNomeEmer() {
    this.associado.NomeEmerSocio = this.form.get('NomeEmerSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['NomeEmerSocio'].setValue(this.form.get('NomeEmerSocio')?.value);
  }

  changeFoneEmer() {
    this.associado.TelefoneEmerSocio = this.form.get('TelefoneEmerSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['TelefoneEmerSocio'].setValue(this.form.get('TelefoneEmerSocio')?.value);
  }

  changeObs() {
    this.associado.ObservacoesSocio = this.form.get('ObservacoesSocio')?.value;
    this.associadosEdicao.EdicaoPrio.controls['ObservacoesSocio'].setValue(this.form.get('ObservacoesSocio')?.value);
  }

}
