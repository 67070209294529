import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { AreaAtuacao } from '../../areaatuacao/models/areaatuacao';
import { AreaAtuacaoService } from '../../areaatuacao/areaatuacao.service';

@Component({
  selector: 'app-area-atuacao-pesquisa',
  templateUrl: './area-atuacao-pesquisa.component.html',
})
export class AreaAtuacaoPesquisaComponent implements OnInit {
  //#region Variables

  @Input() CodigoAreaAtuacao: number = 0;

  @Input() CodigoAreaAtuacaoPreench: number = 0;
  @Input() disabled: boolean = false;

  @Output() AreaAtuacaoOut: EventEmitter<AreaAtuacao> =
    new EventEmitter<AreaAtuacao>();

  AreaAtuacaoObj: AreaAtuacao = new AreaAtuacao();
  private listaAreaAtuacaoOrigem: AreaAtuacao[] = [];
  listaAreaAtuacaoTela: AreaAtuacao[] = [];
  isLoading: boolean = true;

  my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: ' registros',
  };

  constructor(
    public bsModalService: BsModalService,
    private projectUtilservice: UtilsService,
    public service: AreaAtuacaoService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.CodigoAreaAtuacaoPreench != 0) {
      if (
        changes['CodigoAreaAtuacaoPreench']?.currentValue !=
        changes['CodigoAreaAtuacaoPreench']?.previousValue
      ) {
        this.GetListaGeral();
        return;
      } else {
        this.AreaAtuacaoObj = new AreaAtuacao();
        return;
      }
    }

    this.AreaAtuacaoObj = new AreaAtuacao();
  }

  GetListaGeral() {
    this.isLoading = true;
    this.service
      .GetListaGeral()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.listaAreaAtuacaoTela = response.AreaAtuacaoLista;
          this.listaAreaAtuacaoOrigem = response.AreaAtuacaoLista;
          this.isLoading = false;

          if (
            this.CodigoAreaAtuacaoPreench != 0 &&
            this.CodigoAreaAtuacaoPreench !=
              this.AreaAtuacaoObj.CodigoAreaAtuacao
          ) {
            this.AreaAtuacaoObj = this.listaAreaAtuacaoOrigem.find(
              (predicate) =>
                predicate.CodigoAreaAtuacao == this.CodigoAreaAtuacaoPreench
            )!;
            this.AreaAtuacaoOut.emit(this.AreaAtuacaoObj);
          }
        },
        error: (error) => {
          this.projectUtilservice.showHttpError(error);
          this.isLoading = false;
        },
      });
  }

  limpaCampo() {
    this.AreaAtuacaoObj = new AreaAtuacao();
    this.CodigoAreaAtuacao = 0;
    this.CodigoAreaAtuacaoPreench = 0;
    this.addNewItem(this.AreaAtuacaoObj);
  }

  private change($event: any) {
    this.isLoading = true;
    this.AreaAtuacaoObj.CodigoAreaAtuacao = $event.CodigoAreaAtuacao;
    this.AreaAtuacaoObj.NomeAreaAtuacao =  $event.NomeAreaAtuacao;
    this.CodigoAreaAtuacao = $event.CodigoAreaAtuacao;
    this.AreaAtuacaoOut.emit($event);
  }

  updateFilter($event: any) {
    const val = $event.target.value.toLowerCase();
    // filter our data
    const temp = this.listaAreaAtuacaoOrigem.filter((elem) => {
      return elem.NomeAreaAtuacao.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.listaAreaAtuacaoTela = temp;
    return true;
  }

  addNewItem(value: AreaAtuacao) {
    this.change(value);
    this.bsModalService.hide('AreaAtuacaoPopUpComponent');
  }

  //#endregion Rotinas formulário
}
