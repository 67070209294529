<div class="d-flex flex-row align-items-center">
  <input
    *ngIf="
      PlanoContaObj == undefined || PlanoContaObj.DescricaoPlanoConta == '';
      else PlanoContaInput
    "
    placeholder="Clique para selecionar a PlanoConta"
    readonly="true"
    type="text"
    class="form-control form-control-lg form-control-search"
  />
  <ng-template #PlanoContaInput
    ><input
      [placeholder]="PlanoContaObj.DescricaoPlanoConta"
      [title]="PlanoContaObj.DescricaoPlanoConta"
      [value]="PlanoContaObj.DescricaoPlanoConta"
      readonly="true"
      type="text"
      class="form-control form-control-lg form-control-search"
  /></ng-template>
  <button
    [ngClass]="
      PlanoContaObj == undefined || PlanoContaObj.DescricaoPlanoConta == ''
        ? 'customSearchButtons form-control-lupa_last'
        : 'customSearchButtons form-control-lupa_midle'
    "
    containerClass="customClass"
    tooltip="A lupa abre a tela de pesquisa"
    [adaptivePosition]="true"
    (click)="
      bsModalService.show(lista, {
        id: 'PlanoContaPopUpComponent',
        class: 'modal-lg'
      });
      GetListaGeral()
    "
  >
    <i class="fa-solid fa-magnifying-glass text-secondary-btn11"></i>
  </button>
  <button
    class="customSearchButtons form-control-close"
    containerClass="customClass"
    tooltip="Limpa a pesquisa feita pela lupa"
    [adaptivePosition]="true"
    *ngIf="
      !(PlanoContaObj == undefined || PlanoContaObj.DescricaoPlanoConta == '')
    "
    (click)="limpaCampo()"
  >
    <i class="fa-solid fa-xmark fa-xl text-secondary-btn15"></i>
  </button>
</div>

<ng-template #lista>
  <div *ngIf="isLoading == false; else Loading">
    <div class="modal-body">
      <div class="card-title-form-menor">
        <span>PlanoContas</span>
        <button
          type="button"
          class="float-end btn-warning customSearchButtons"
          aria-label="Fechar"
          containerClass="customClass"
          tooltip="Fechar Pesquisa"
          placement="bottom"
          [adaptivePosition]="false"
          (click)="bsModalService.hide('PlanoContaPopUpComponent')"
        >
          <i class="fa-solid fa-xmark fa-xl"></i>
        </button>
      </div>
      <div class="card col-lg-12">
        <div class="card-body">
          <div style="margin-bottom: 1%">
            <span>
              <input
                type="text"
                style="width: 100%; height: 32px"
                #search
                id="search"
                class="form-control form-control-lg"
                placeholder="Pesquisar Descrição na listagem abaixo..."
                (keyup)="updateFilter($event)"
              />
            </span>
          </div>
          <div>
            <ngx-datatable
              #table
              class="bootstrap ngx-datatable"
              [rows]="this.listaPlanoContaTela"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              rowHeight="auto"
              [scrollbarH]="false"
              [scrollbarV]="false"
              [limit]="8"
              [messages]="my_messages"
            >
              <ngx-datatable-column
                name="PlanoConta"
                prop="DescricaoPlanoConta"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a (click)="addNewItem(row)">{{ row.DescricaoPlanoConta }}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column
                name="Tipo"
                prop="Tipo"
                [cellClass]="'ngx-direita'"
                [headerClass]="'ngx-direita'"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a (click)="addNewItem(row)">{{ row.Tipo }}</a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #Loading>
    <div>
      <div class="modal-body">
        <div class="card-title">
          <span style="margin-left: 2%">
            <i class="fas fa-user ml-1"></i>PlanoContas</span
          >
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="bsModalService.hide('PlanoContaPopUpComponent')"
          >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="card col-lg-12">
          <div class="card-body">
            <div style="margin-bottom: 1%">
              <span>
                <input
                  type="text"
                  style="width: 100%; height: 32px"
                  #search
                  id="search"
                  class="form-control form-control-lg"
                  placeholder="Carregando Lista PlanoContas"
                />
              </span>
            </div>
            <div class="placeholder-glow">
              <table class="table my-2">
                <tbody>
                  <tr>
                    <td><span class="w-100 px-4 placeholder"></span></td>
                    <td><span class="w-100 px-4 placeholder"></span></td>
                  </tr>
                  <tr>
                    <td><span class="w-100 px-4 placeholder"></span></td>
                    <td><span class="w-100 px-4 placeholder"></span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
