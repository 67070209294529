export class UsuarioLogado {

  Nome: string;
  Email: string;
  Token: string;
  mnuCatVisible: boolean;
  mnuPlanoVisible: boolean;
  mnuSalaVisible: boolean;
  mnuSalaCadVisible: boolean;
  mnuSalaOcuVisible: boolean;
  mnuAdminVisible: boolean;
  mnuSocioVisible: boolean;
  mnuPessoasVisible: boolean;
  mnuSocioConVisible: boolean;
  mnuSocioAgendaVisible: boolean;
  mnuRotinasVisible: boolean;
  mnuRelatoVisible: boolean;
  mnuConsultaVisible: boolean;
  mnuConsultaAvalVisible: boolean;
  mnuConsultaEstaVisible: boolean;
  mnuConsultaFilaVisible: boolean;
  mnuConsultaConVisible: boolean;
  mnuConsultaFerVisible: boolean;
  mnuAtividadesAlVisible: boolean;
  mnuEnsinoVisible: boolean;
  mnuCientificoVisible: boolean;
  mnuAreaAtuacaoVisible: boolean;
  mnuPrevisaoVisible: boolean;
  mnuOrcamentoVisible: boolean;
  mnuLancaVisible: boolean;
  mnuSaldoVisible: boolean;
  mnuAtividadesAdVisible: boolean;
  mnuEnsinoNomeVisible: boolean;
  mnuConsultaEnsinoVisible: boolean;
  mnuEnsinosVisible: boolean;
  mnuTrabalhoAnualVisible: boolean;
  mnuTrabalhoConVisible: boolean;
  mnuSupervisoesSaVisible: boolean;
  mnuSupervisoesSiVisible: boolean;
  mnuAtividadesSeVisible: boolean;

  constructor() {
    this.Nome = '';
    this.Email = '';
    this.Token = '';
    this.mnuCatVisible = false;
    this.mnuPlanoVisible = false;
    this.mnuSalaVisible = false;
    this.mnuSalaCadVisible = false;
    this.mnuSalaOcuVisible = false;
    this.mnuAdminVisible = false;
    this.mnuSocioVisible = false;
    this.mnuPessoasVisible = false;
    this.mnuSocioConVisible = false;
    this.mnuSocioAgendaVisible = false;
    this.mnuRotinasVisible = false;
    this.mnuRelatoVisible = false;
    this.mnuConsultaVisible = false;
    this.mnuConsultaAvalVisible = false;
    this.mnuConsultaEstaVisible = false;
    this.mnuConsultaFilaVisible = false;
    this.mnuConsultaConVisible = false;
    this.mnuConsultaFerVisible = false;
    this.mnuAtividadesAlVisible = false;
    this.mnuEnsinoVisible = false;
    this.mnuCientificoVisible = false;
    this.mnuAreaAtuacaoVisible = false;
    this.mnuPrevisaoVisible = false;
    this.mnuOrcamentoVisible = false;
    this.mnuLancaVisible = false;
    this.mnuSaldoVisible = false;
    this.mnuAtividadesAdVisible = false;
    this.mnuEnsinoNomeVisible = false;
    this.mnuConsultaEnsinoVisible = false;
    this.mnuEnsinosVisible = false;
    this.mnuTrabalhoAnualVisible = false;
    this.mnuTrabalhoConVisible = false;
    this.mnuSupervisoesSaVisible = false;
    this.mnuSupervisoesSiVisible = false;
    this.mnuAtividadesSeVisible = false;
  }

}
