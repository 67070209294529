import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  preserveWhitespaces: true
})

export class FooterComponent implements OnInit {

  //Variáveis do formulário
  year: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

}
