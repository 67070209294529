<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">

    <!-- Solicitações -->
    <div>
      <div class="card-title-form">Cadastro Geral
        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
          [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
          aria-controls="offcanvasExample">
        </span>
        <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Adicionar" placement="top"
          [adaptivePosition]="false">
          <button type="button" class="btn btn-sm btn-warning float-end" (click)="Adicionar();"><i
            class="fa-solid fa-plus icone-para-botao"></i>Adicionar</button>
        </span>
      </div>

      <a style="margin-bottom: 0.5%;text-align: left;padding:0.6rem 0.8rem;font-weight: bolder;"
        class="w-100 btn btn-light" href="#filtros" data-bs-toggle="collapse"><i class="fa-solid fa-filter iconeTamanho"></i>Filtrar Pesquisa</a>

      <div class="collapse" id="filtros">
        <div class="table-responsive">
          <div class="w-100 row">
            <div class="col-lg-4">
              <label class="form-label">Categoria</label>
              <app-categoria-pesquisa (CategoriaOut)="changeCategoria($event);">
              </app-categoria-pesquisa>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Área Atuação</label>
              <app-area-atuacao-pesquisa (AreaAtuacaoOut)="changeArea($event);">
              </app-area-atuacao-pesquisa>
            </div>
            <div class="col-lg-3 row">
              <span class="col-lg-6">
                <label class="form-label">Bolsista?</label>
                <span class="d-block"> <ui-switch  (change)="changeBolsista($event);"></ui-switch></span>
              </span>
              <span class="col-lg-6">
                <label class="form-label">Ações Afirmativas?</label>
                <span class="d-block"> <ui-switch  (change)="changeAcoesAfirmativas($event);"></ui-switch></span>
              </span>
            </div>
            <div class="col-lg-1 posiociona-botao-final">
              <button type="button" class="btn btn-sm btn-warning" (click)="ListaGeral();"><i
                  class="fa-solid fa-search icone-para-botao"></i>Pesquisar</button>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 0.5%;margin-bottom: 0.5%;" class="row w-100">
        <span class="col-lg-10">
          <input type="text" #search id="search" class="form-control form-control-lg "
            placeholder="Pesquisar nome, categoria ou área de atuação na listagem abaixo..."
            (keyup)="updateFilter($event)" />
        </span>
        <span class="col-lg-2">
          <button type="button" class="btn btn-sm btn-warning float-end" containerClass="customClass" tooltip="Exporta para o Excel os dados consultados abaixo." placement="top"
          [adaptivePosition]="false" (click)="generateExcell()"><i class="fa-solid fa-file-excel icone-para-botao"></i>Exportar Excel</button>
        </span>
      </div>

      <div class="card-body-form">
        <div class="table-responsive table-responsive-overflow-450">
          <ngx-datatable #myTable class="bootstrap ngx-datatable" [rows]="associados"
            [columnMode]="!isMobile ? 'flex' : 'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
            [scrollbarH]="false" [scrollbarV]="false" [limit]="5" [messages]="my_messages"
            [sorts]="[{prop: 'NomeSocio ', dir: 'asc'}]">

            <ngx-datatable-row-detail #myDetailRow [rowHeight]="100">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="card-valores ngx-datatable-row-detail_Aparencia">

                  <div class="row">
                    <div class="col-lg-6">
                      <div class="card-title-form-menor-FB">Acesso</div>
                      <div class="row">
                        <div class="col-lg-3">
                          <label class="form-label">Usuário:</label>
                          <input type="text" class="form-control form-control-lg " [value]="row.UsuarioSocio" disabled>
                        </div>
                        <div class="col-lg-3">
                          <label class="form-label">Perfil:</label>
                          <input type="text" class="form-control form-control-lg " [value]="row.PerfilSocio" disabled>
                        </div>
                        <div class="row col-lg-6">
                          <span class="col-lg-4">
                            <label class="form-label">Ativo?</label>
                            <span class="d-block" disabled="disabled"> <ui-switch [disabled]="true"
                                [(ngModel)]="row.AtivoSocio"></ui-switch></span>
                          </span>
                          <span class="col-lg-4">
                            <label class="form-label">Bolsista?</label>
                            <span class="d-block" disabled="disabled"> <ui-switch [disabled]="true"
                                [(ngModel)]="row.BolsistaSocio"></ui-switch></span>
                          </span>
                          <span class="col-lg-4">
                            <label class="form-label">Ações Afirmativas?</label>
                            <span class="d-block" disabled="disabled"> <ui-switch [disabled]="true"
                                [(ngModel)]="row.AcoesAfirmativasSocio"></ui-switch></span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-title-form-menor-FB">Telefones</div>
                      <div class="row">
                        <div class="col-lg-4">
                          <label class="form-label">Telefone 1:</label>
                          <input type="text" class="form-control form-control-lg "
                            [value]="row.Telefone1Socio | mask:'(00) 00000-0000'" disabled>
                        </div>
                        <div class="col-lg-4">
                          <label class="form-label">Telefone 2:</label>
                          <input type="text" class="form-control form-control-lg "
                            [value]="row.Telefone2Socio | mask:'(00) 00000-0000'" disabled>
                        </div>
                        <div class="col-lg-4">
                          <label class="form-label">Telefone 3:</label>
                          <input type="text" class="form-control form-control-lg "
                            [value]="row.Telefone3Socio | mask:'(00) 00000-0000'" disabled>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row top1 bottom1">
                    <div class="col-lg-8">
                      <div class="card-title-form-menor-FB">Endereço Principal</div>
                      <div class="row">
                        <div class="col-lg-12">
                          <label class="form-label">Endereço:</label>
                          <input type="text" class="form-control form-control-lg " [value]="row.EnderecoSocio" disabled>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4">
                          <label class="form-label">Bairro:</label>
                          <input type="text" class="form-control form-control-lg " [value]="row.Bairro" disabled>
                        </div>
                        <div class="col-lg-4">
                          <label class="form-label">Cidade:</label>
                          <input type="text" class="form-control form-control-lg " [value]="row.Cidade" disabled>
                        </div>
                        <div class="col-lg-2">
                          <label class="form-label">Estado:</label>
                          <input type="text" class="form-control form-control-lg " [value]="row.UF" disabled>
                        </div>
                        <div class="col-lg-2">
                          <label class="form-label">CEP:</label>
                          <input type="text" class="form-control form-control-lg "
                            [value]="row.CepSocio | mask:'00000-000'" disabled>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-4">
                      <div class="card-title-form-menor-FB">Informações Relevantes</div>
                      <div class="col-lg-12">
                        <label class="form-label">Observações:</label>
                        <textarea class="form-control form-control-lg" rows="4" disabled
                          [value]="row.ObservacoesSocio"></textarea>
                      </div>
                    </div>
                  </div>

                </div>
              </ng-template>
            </ngx-datatable-row-detail>

            <ngx-datatable-column prop="NomeSocio" [draggable]="false" [flexGrow]="4">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Nome Associado</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Nome</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.NomeSocio}}" placement="auto"
                  [adaptivePosition]="false">{{row.NomeSocio }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="Categoria" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Categoria</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Categoria</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.Categoria2 == '' || row.Categoria2 == null" containerClass="customClass" tooltip="{{row.Categoria}}" placement="auto"
                  [adaptivePosition]="false">{{row.Categoria}}</span>
                <span *ngIf="row.Categoria2 != '' && row.Categoria2 != null" containerClass="customClass" tooltip="{{row.Categoria}} / {{row.Categoria2}}" placement="auto"
                  [adaptivePosition]="false">{{row.Categoria}} / {{row.Categoria2}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="IngressoFormacaoSocio" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Ingresso Formação</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Formação</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.IngressoFormacaoSocio | mask:'00/0000' }}" placement="auto"
                  [adaptivePosition]="false">{{row.IngressoFormacaoSocio | mask:'00/0000' }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="AreaAtuacao" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">Área Atuação</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">Área</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.AreaAtuacao}}" placement="auto"
                  [adaptivePosition]="false">{{row.AreaAtuacao}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column prop="EmailSocio" [draggable]="false" [flexGrow]="2">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!isMobile" class="ngx-datatable-cabecalho">E-mail</span>
                <span *ngIf="isMobile" class="ngx-datatable-cabecalho">E-mail</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span containerClass="customClass" tooltip="{{row.EmailSocio}}" placement="auto"
                  [adaptivePosition]="false">{{row.EmailSocio}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Ações" [sortable]="false"  [cellClass]="'overflow-visible ngx-direita'"
              [headerClass]="'ngx-direita'" [draggable]="false" [flexGrow]="2">
              <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                <a href="javascript:void(0)" (click)="toggleExpandRow(row)">
                  <span *ngIf="!expanded" class="iconeTamanho" containerClass="customClass"
                    tooltip="Mostrar Informações Adicionais" placement="top" [adaptivePosition]="false">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span *ngIf="expanded" class="iconeTamanho" containerClass="customClass"
                    tooltip="Ocultar Informações Adicionais" placement="top" [adaptivePosition]="false">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
                <span class="iconeTamanho" containerClass="customClass" tooltip="Edição" placement="top"
                  [adaptivePosition]="false" (click)="Edicao(row.CodigoSocio); ">
                  <i class="fa-solid fa-pen-to-square"></i></span>
                <span class="iconeTamanho" containerClass="customClass" tooltip="Mensalidades"
                  placement="top" [adaptivePosition]="false" (click)="Mensalidade(row.CodigoSocio, row.NomeSocio); ">
                  <i class="fa-solid fa-credit-card"></i></span>
                <span class="iconeTamanho" containerClass="customClass" tooltip="Disponibilidade"
                  placement="top" [adaptivePosition]="false" (click)="Disponibilidade(row.CodigoSocio); ">
                  <i class="fa-solid fa-calendar"></i></span>
                <span class="iconeTamanho" containerClass="customClass" tooltip="Histórico" placement="top"
                  [adaptivePosition]="false" (click)="Historico(row.CodigoSocio); ">
                  <i class="fa-solid fa-graduation-cap"></i></span>
                <span class="iconeTamanho" containerClass="customClass" tooltip="Exclusão" placement="top" style="padding-left: 6% !important;padding-right: 4% !important;"
                  [adaptivePosition]="false" (click)="openModalExportar(confirmaExclusao, row.CodigoSocio, row.NomeSocio);">
                  <i class="fa-solid fa-trash"></i></span>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>
<app-footer></app-footer>

<ng-template #confirmaExclusao>
  <div>
    <div class="modal-header">
      <h5 class="modal-title">Confirmação de Exclusão</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"></span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirma a exclusão do associado '{{nomeAssociado}}'?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ExcluiAssociadoByCodigo()">Sim</button>
      <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
    </div>
  </div>
</ng-template>
