import { Component, OnInit } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Utils } from 'src/app/Projeto/Utils/utils';

@Component({
  selector: 'app-sala-ocupacao',
  templateUrl: './salas-ocupacao.component.html',
  styleUrls: ['./salas-ocupacao.component.css'],
})

export class SalasOcupacaoComponent implements OnInit {
  constructor() {}
  aba: string = 'S1';

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
  }

  isActiveAba(aba: string): boolean {
    return this.aba == aba;
  }

  setAba(aba: string) {
    this.aba = aba;
  }

}
