import { valoresDash } from './models/ret-valores';
import { Component, OnInit } from '@angular/core';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { BrowserModule } from '@angular/platform-browser';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from './dashboard.service';
import { take } from 'rxjs';
import { MessageService } from 'src/app/Projeto/message/message.service';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { Utils } from 'src/app/Projeto/Utils/utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private utilsservice: UtilsService,
    private relService: DashboardService
  ) { }

  public valoresDash: valoresDash;
  public dataFormatada: string = '';
  multiValores: any[];
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showDataLabel: boolean = true;
  showLegend: boolean = false;
  legendPosition = LegendPosition.Below;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Meses';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Valores';
  legendTitle: string = 'Faturamento';


  colorScheme = [
    { name: 'Despesa', value: '#5D0719' },
    { name: 'Receita', value: '#004439' },
  ];

  ngOnInit(): void {
    this.spinner.show();
    try {
      this.relService.ValoresDashBoard()
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            else
            {
              this.valoresDash = response.valoresDash;
              this.dataFormatada = Utils.getDateddMMyyy(response.valoresDash.DataSaldo);
              this.multiValores = response.valoresDash.multi;
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  onSelect(data: any): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
