import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Associado, SocioDispResumo } from '../models/associados';
import { AssociadosService } from '../associados.service';
import { Lista } from '../../models-gerais/lista';
import { ListasGeraisService } from '../../listasgerais/listasgerais.service';

@Component({
  selector: 'app-associados-edicao-disponibilidade',
  templateUrl: './associados-edicao-disponibilidade.component.html',
  styleUrls: ['./associados-edicao-disponibilidade.component.css'],
})
export class AssociadosEdicaoDispComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  CodigoSocio: number  = 0;
  SocioDisponibilidade: SocioDispResumo = new SocioDispResumo();
  SocioDisponibilidadeVazio: SocioDispResumo = new SocioDispResumo();
  form: FormGroup;
  TipoAtendimento: Lista = new Lista();
  aba: string = 'S1';
  configTipo = {
    search: true,
    placeholder: 'Selecione o tipo', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO+
    height: 'auto',
    limitTo: 0
  };
  Perfil: Lista = new Lista();
  configPerfil = {
    search: true,
    placeholder: 'Selecione perfil', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO
    height: '220px',
    limitTo: 0,
  };
  public my_messages = {
    emptyMessage: 'Consulta não retornou registros.',
    totalMessage: 'linhas encontradas.',
  };
  public my_messagesVazio = {
    emptyMessage: 'Identifica as possibilidades de atendimento presencial e on line por dia/hora',
    totalMessage: 'linhas encontradas.',
  };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private associadosService: AssociadosService,
    private spinner: NgxSpinnerService,
    private utilsservice: UtilsService,
    private listasGeraisService: ListasGeraisService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { CodigoSocio } = window.history.state;
    this.CodigoSocio = CodigoSocio;
    this.criarForm();
    this.GetListasFixas();

  }

  GetDisponibilidadeByCodigoSocio() {
    this.spinner.show();
    try {
      this.associadosService
        .GetDisponibilidadeByCodigoSocio(this.CodigoSocio)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.SocioDisponibilidade = response.AssociadoDisponibilidade;
              if (this.SocioDisponibilidade.DataAfastIni != null)
              { this.SocioDisponibilidade.DataAfastIni = Utils.getDateUTC(this.SocioDisponibilidade.DataAfastIni); }
              if (this.SocioDisponibilidade.DataAfastFim != null)
              { this.SocioDisponibilidade.DataAfastFim = Utils.getDateUTC(this.SocioDisponibilidade.DataAfastFim); }
              this.form.controls['PerfilSocioDescr'].setValue(this.SocioDisponibilidade.PerfilSocioDescr);
              this.form.controls['DescrTipoAtendimentoSocio'].setValue(this.SocioDisponibilidade.DescrTipoAtendimentoSocio);
              this.form.controls['DisponivelSocio'].setValue(this.SocioDisponibilidade.DisponivelSocio);
              this.form.controls['LimiteAtendimentoSocio'].setValue(this.SocioDisponibilidade.LimiteAtendimentoSocio);
              this.form.controls['LimiteAtendimentoSocioCri'].setValue(this.SocioDisponibilidade.LimiteAtendimentoSocioCri);
              this.form.controls['DataAfastIni'].setValue(this.SocioDisponibilidade.DataAfastIni);
              this.form.controls['DataAfastFim'].setValue(this.SocioDisponibilidade.DataAfastFim);
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }

  }

  criarForm() {
    this.form = this.formBuilder.group({
      PerfilSocioDescr: [''],
      DescrTipoAtendimentoSocio: [''],
      DisponivelSocio: [false],
      LimiteAtendimentoSocio: [0],
      LimiteAtendimentoSocioCri: [0],
      DataAfastIni: [''],
      DataAfastFim: ['']
    });
  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('TipoAtendimento');
    ArrayListas.push('Perfil');
    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.TipoAtendimento = response.ListasFixas[0];
              this.Perfil = response.ListasFixas[1];
              this.GetDisponibilidadeByCodigoSocio();
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  Salva() {
    this.spinner.show();
    try {
      this.associadosService
        .SalvaDisponibilidade( this.SocioDisponibilidade)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            }
            else
            { this.voltar(); }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }

  }

  voltar() {
    this.router.navigateByUrl('/cadastrogeral/lista');
  }

  isActiveAba(aba: string): boolean {
    return this.aba == aba;
  }

  setAba(aba: string) {
    this.aba = aba;
  }

  changePerfil($event: any) {
    this.SocioDisponibilidade.PerfilSocio = $event.value.Valor;
    this.SocioDisponibilidade.PerfilSocioDescr = $event.value.Descricao;
  }

  changeDisponibilidade($event: any) {
    this.SocioDisponibilidade.DisponivelSocio = $event;
  }

  changeTipoAtendimentoSocio($event: any) {
    this.SocioDisponibilidade.TipoAtendimentoSocio  = $event.value.Valor;
    this.SocioDisponibilidade.DescrTipoAtendimentoSocio = $event.value.Descricao;
  }

  changeLimiteAtendimentoSocio() {
    this.SocioDisponibilidade.LimiteAtendimentoSocio = this.form.get("LimiteAtendimentoSocio")?.value;
  }

  changeLimiteAtendimentoSocioCri() {
    this.SocioDisponibilidade.LimiteAtendimentoSocioCri = this.form.get("LimiteAtendimentoSocioCri")?.value;
  }

  AlteraData($event: any,data: string)
  {
    if (data == 'DataAfastIni')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.SocioDisponibilidade.DataAfastIni = new Date($event);
    }
    else if (data == 'DataAfastFim')
    {
      //Gera nova data para eliminar a hora que vem junto mesmo quando é somente a data
      this.SocioDisponibilidade.DataAfastFim = new Date($event);
    }
  }

  Segunda(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSegConsulta = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSeg = $event ? 1 : 0;
  }

  Terca(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispTerConsulta = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispTer = $event ? 1 : 0;
  }

  Quarta(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQuaConsulta = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQua = $event ? 1 : 0;
  }

  Quinta(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQuiConsulta = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQui = $event ? 1 : 0;
  }

  Sexta(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSexConsulta = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSex = $event ? 1 : 0;
  }

  SegundaOn(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSegConsultaOn = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSegOn = $event ? 1 : 0;
  }

  TercaOn(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispTerConsultaOn = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispTerOn = $event ? 1 : 0;
  }

  QuartaOn(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQuaConsultaOn = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQuaOn = $event ? 1 : 0;
  }

  QuintaOn(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQuiConsultaOn = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispQuiOn = $event ? 1 : 0;
  }

  SextaOn(row: any,$event: any) {
    const index: number =  this.SocioDisponibilidade.SocioDispResHorarios.indexOf(row);
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSexConsultaOn = $event;
    this.SocioDisponibilidade.SocioDispResHorarios[index].DispSexOn = $event ? 1 : 0;
  }

}
