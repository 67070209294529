import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Lista } from '../models-gerais/lista';
import { RetListas } from '../models-gerais/ret-listas';
import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root'
})

export class ListasGeraisService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.ListaWS}`;
  }

  GetListasFixas(ArrayListas: string[]): Observable<RetListas> {

    let listaString: string = JSON.stringify(ArrayListas);
    const url = `${this.baseURL}/GetListasFixas`;
    return this.httpClient.post<RetListas>(url, listaString).pipe(
      take(1),
      map((response: RetListas) => {
        return response;
      })
    );

  }

}
