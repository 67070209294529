<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid col-lg-12">

    <div class="table-responsive table-responsive-overflow-600">

      <div class="row top02">
        <div class="col-lg-3 col-md-6">
          <div class="panel panel-green">
            <div class="panel-heading">
              <div class="row">
                <div class="col-lg-3">
                  <i class="fa fa-coins fa-5x"></i>
                </div>
                <div class="col-lg-9 text-end">
                  <div class="fw-bold">Saldos em {{ this.dataFormatada }}</div>
                  <div>Conta: {{ this.valoresDash.ValorContaCorrente | currency: 'BRL' }}</div>
                  <div>Aplicação: {{ this.valoresDash.ValorAplicacao | currency: 'BRL' }}</div>
                  <div>Fundo Fixo: {{ this.valoresDash.ValorFundoFixo | currency: 'BRL' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panel panel-primary">
            <div class="panel-heading">
              <div class="row">
                <div class="col-lg-3">
                  <i class="fa fa-comments fa-5x"></i>
                </div>
                <div class="col-lg-9 text-end">
                  <div class="fw-bold">Quantidade Atendimentos</div>
                  <div>Qtde Adultos Hoje: {{ this.valoresDash.QtAdultos }}</div>
                  <div>Qtde Crianças Hoje: {{ this.valoresDash.QtCriancas }}</div>
                  <div>Consultas Adultos Mês Ant.: {{ this.valoresDash.QtConsultaAdultos }}</div>
                  <div>Consultas Crianças Mês Ant.: {{ this.valoresDash.QtConsultaCriancas }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panel panel-yellow">
            <div class="panel-heading">
              <div class="row">
                <div class="col-lg-3">
                  <i class="fa fa-question fa-5x"></i>
                </div>
                <div class="col-lg-9 text-end">
                  <div class="huge">??</div>
                  <div>Aqui cabe mais um</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="panel panel-red">
            <div class="panel-heading">
              <div class="row">
                <div class="col-lg-3">
                  <i class="fa fa-tasks fa-5x"></i>
                </div>
                <div class="col-lg-9 text-end">
                  <div class="huge">YY</div>
                  <div>Seminários Abertos</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ngx-charts-bar-vertical-2d [customColors]="colorScheme" [results]="multiValores" [gradient]="gradient"
          [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
          [showDataLabel]="showDataLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel" [legendTitle]="legendTitle" [legendPosition]="legendPosition"
          (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
        </ngx-charts-bar-vertical-2d>
      </div>

    </div>

  </div>
</div>

<app-footer></app-footer>
