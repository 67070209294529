import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetSocioResumoLista , RetSocioResumo, RetAssociado, RetAssociadoDisp, RetAssociadoMens, RetEnsinoMens } from './models/ret-associados';

import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';
import { RetEndereco } from '../models-gerais/ret-Endereco';
import { Associado, EnsinoMensalidade, SocioDispResumo, SocioMensalidade } from './models/associados';

@Injectable({
  providedIn: 'root'
})

export class AssociadosService {

  private readonly baseURL: string = '';
  private readonly baseURLDisp: string = '';
  private readonly baseURLMens: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.AssociadosWS}`;
    this.baseURLDisp = `${environment.AssociadosDispWS}`;
    this.baseURLMens = `${environment.AssociadosMensWS}`;
  }

  ListaGeral(CodigoAreaAtuacao: number, CodigoCategoria: number, BolsistaSocio: boolean, AcoesAfirmativasSocio: boolean): Observable<RetSocioResumoLista> {

    const params = new HttpParams()
    .set('CodigoAreaAtuacao', CodigoAreaAtuacao)
    .set('CodigoCategoria', CodigoCategoria)
    .set('BolsistaSocio', BolsistaSocio)
    .set('AcoesAfirmativasSocio', AcoesAfirmativasSocio);

    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetSocioResumoLista>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaTodosSocios(): Observable<RetSocioResumoLista> {

    const url = `${this.baseURL}/ListaTodosSocios`;
    return this.httpClient.get<RetSocioResumoLista>(url).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaAssociados(): Observable<RetSocioResumoLista> {

    const url = `${this.baseURL}/ListaAssociados`;
    return this.httpClient.get<RetSocioResumoLista>(url).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaEstagiarios(): Observable<RetSocioResumoLista> {

    const url = `${this.baseURL}/ListaEstagiarios`;
    return this.httpClient.get<RetSocioResumoLista>(url).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaTerapeutas(): Observable<RetSocioResumoLista> {

    const url = `${this.baseURL}/ListaTerapeutas`;
    return this.httpClient.get<RetSocioResumoLista>(url).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaPacientes(): Observable<RetSocioResumoLista> {

    const url = `${this.baseURL}/ListaPacientes`;
    return this.httpClient.get<RetSocioResumoLista>(url).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  ListaFornecedores(): Observable<RetSocioResumoLista> {

    const url = `${this.baseURL}/ListaFornecedores`;
    return this.httpClient.get<RetSocioResumoLista>(url).pipe(
      take(1),
      map((response: RetSocioResumoLista) => {
        return response;
      })
    );

  }

  BuscaCep(CEP: string): Observable<RetEndereco> {

    const params = new HttpParams()
    .set('CEP', CEP)

    const url = `${this.baseURL}/BuscaCep`;
    return this.httpClient.get<RetEndereco>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetEndereco) => {
        return response;
      })
    );

  }

  GetAssociadoByCodigo(CodigoAssociado: number): Observable<RetAssociado> {

    const params = new HttpParams()
    .set('CodigoAssociado', CodigoAssociado)

    const url = `${this.baseURL}/GetAssociadoByCodigo`;
    return this.httpClient.get<RetAssociado>(url, { "params": params }).pipe(
      take(1),
      map((response: RetAssociado) => {
        return response;
      })
    );

  }

  SalvaAssociado(associado:  Associado): Observable<RetError> {

    let bodyString = JSON.stringify(associado);

    const url = `${this.baseURL}/SalvaAssociado`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }

  ExcluiAssociadoByCodigo(CodigoAssociado: number): Observable<RetError> {

    const params = new HttpParams()
    .set('CodigoAssociado', CodigoAssociado)

    const url = `${this.baseURL}/ExcluiAssociadoByCodigo`;
    return this.httpClient.get<RetError>(url, { "params": params }).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }

  GetDisponibilidadeByCodigoSocio(CodigoAssociado: number): Observable<RetAssociadoDisp> {

    const params = new HttpParams()
    .set('CodigoAssociado', CodigoAssociado)

    const url = `${this.baseURLDisp}/GetDisponibilidadeByCodigoSocio`;
    return this.httpClient.get<RetAssociadoDisp>(url, { "params": params }).pipe(
      take(1),
      map((response: RetAssociadoDisp) => {
        return response;
      })
    );

  }

  SalvaDisponibilidade(AssociadoDisponibilidade:  SocioDispResumo): Observable<RetError> {

    let bodyString = JSON.stringify(AssociadoDisponibilidade);

    const url = `${this.baseURLDisp}/Salva`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }

  GetMensalidadeByCodigoSocio(CodigoAssociado: number): Observable<RetAssociadoMens> {

    const params = new HttpParams()
    .set('CodigoAssociado', CodigoAssociado)

    const url = `${this.baseURLMens}/GetMensalidadeByCodigoSocio`;
    return this.httpClient.get<RetAssociadoMens>(url, { "params": params }).pipe(
      take(1),
      map((response: RetAssociadoMens) => {
        return response;
      })
    );

  }

  ExcluiMensalidadeByCodigo(CodigoMensalidade: number,Sigla: string ): Observable<RetError> {

    const params = new HttpParams()
    .set('CodigoMensalidade', CodigoMensalidade)
    .set('Sigla', Sigla)

    const url = `${this.baseURLMens}/ExcluiMensalidadeByCodigo`;
    return this.httpClient.get<RetError>(url, { "params": params }).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }

  GetEnsinoMensalidadeByCodigo(CodigoMensalidade: number,Sigla: string ): Observable<RetEnsinoMens> {

    const params = new HttpParams()
    .set('CodigoMensalidade', CodigoMensalidade)
    .set('Sigla', Sigla)

    const url = `${this.baseURLMens}/GetEnsinoMensalidadeByCodigo`;
    return this.httpClient.get<RetEnsinoMens>(url, { "params": params }).pipe(
      take(1),
      map((response: RetEnsinoMens) => {
        return response;
      })
    );

  }

  SalvaMensalidade(EnsinoMensalidade:  EnsinoMensalidade): Observable<RetError> {

    let bodyString = JSON.stringify(EnsinoMensalidade);

    const url = `${this.baseURLMens}/Salva`;
    return this.httpClient.post<RetError>(url, bodyString).pipe(
      take(1),
      map((response: RetError) => {
        return response;
      })
    );

  }

}
