<div class="table-responsive table-responsive-overflow-450">

  <table class="table rounded-corners">
    <tbody>
      <tr class="tableFont">
        <th>Horário</th>
        <th>Segunda</th>
        <th>Terça</th>
        <th>Quarta</th>
        <th>Quinta</th>
        <th>Sexta</th>
      </tr>
      <tr *ngFor="let row of this.salasOcu let iDesc = index" class="tableFont">
        <td>{{row.Horario}}</td>
        <td [ngClass]="this.salasOcu[iDesc].Segunda[0].NomeTerapeuta == '' && this.salasOcu[iDesc].Segunda[0].NomePaciente == '' ? '' : 'bg-primary'">
          <div *ngFor="let ocupacao of row.Segunda; let indice = index" >
            <div *ngIf="ocupacao.NomeTerapeuta != ''" [ngClass]="indice == 0 ? '' : 'linhaSuperior' "
              containerClass="customClass" tooltip="{{ocupacao.NomeTerapeuta}}" placement="auto"
              [adaptivePosition]="false">
              {{ocupacao.NomeTerapeuta}}</div>
            <div *ngIf="ocupacao.NomePaciente != ''">
              <label class="col-lg-11" containerClass="customClass" tooltip="{{ocupacao.NomePaciente}}" placement="auto"
                [adaptivePosition]="false">
                {{ocupacao.NomePaciente}}</label>
              <i *ngIf="row.CodigoSala != 9" class="fa-solid fa-trash"
                (click)="openModalExportar(confirmaLiberacao, row.CodigoSala,ocupacao.NomeTerapeuta, ocupacao.NomePaciente, row.HorarioConsulta, ocupacao.DiaSemanaConsulta, ocupacao.CodigoTerapeuta, ocupacao.CodigoPaciente);"
                containerClass="customClass" tooltip="Libera Sala" placement="auto" [adaptivePosition]="false">
              </i>
            </div>
          </div>
        </td>
        <td [ngClass]="this.salasOcu[iDesc].Terca[0].NomeTerapeuta == '' && this.salasOcu[iDesc].Terca[0].NomePaciente == '' ? '' : 'bg-primary'">
          <div *ngFor="let ocupacao of row.Terca; let indice = index">
            <div *ngIf="ocupacao.NomeTerapeuta != ''" [ngClass]="indice == 0 ? '' : 'linhaSuperior' "
              containerClass="customClass" tooltip="{{ocupacao.NomeTerapeuta}}" placement="auto"
              [adaptivePosition]="false">
              {{ocupacao.NomeTerapeuta}}</div>
            <div *ngIf="ocupacao.NomePaciente != ''">
              <label class="col-lg-11" containerClass="customClass" tooltip="{{ocupacao.NomePaciente}}" placement="auto"
                [adaptivePosition]="false">
                {{ocupacao.NomePaciente}}</label>
              <i *ngIf="row.CodigoSala != 9" class="fa-solid fa-trash"
                (click)="openModalExportar(confirmaLiberacao, row.CodigoSala,ocupacao.NomeTerapeuta, ocupacao.NomePaciente, row.HorarioConsulta, ocupacao.DiaSemanaConsulta, ocupacao.CodigoTerapeuta, ocupacao.CodigoPaciente);"
                containerClass="customClass" tooltip="Libera Sala" placement="auto" [adaptivePosition]="false">
              </i>
            </div>
          </div>
        </td>
        <td [ngClass]="this.salasOcu[iDesc].Quarta[0].NomeTerapeuta == '' && this.salasOcu[iDesc].Quarta[0].NomePaciente == '' ? '' : 'bg-primary'">
          <div *ngFor="let ocupacao of row.Quarta; let indice = index">
            <div *ngIf="ocupacao.NomeTerapeuta != ''" [ngClass]="indice == 0 ? '' : 'linhaSuperior' "
              containerClass="customClass" tooltip="{{ocupacao.NomeTerapeuta}}" placement="auto"
              [adaptivePosition]="false">
              {{ocupacao.NomeTerapeuta}}</div>
            <div *ngIf="ocupacao.NomePaciente != ''">
              <label class="col-lg-11" containerClass="customClass" tooltip="{{ocupacao.NomePaciente}}" placement="auto"
                [adaptivePosition]="false">
                {{ocupacao.NomePaciente}}</label>
              <i *ngIf="row.CodigoSala != 9" class="fa-solid fa-trash"
                (click)="openModalExportar(confirmaLiberacao, row.CodigoSala,ocupacao.NomeTerapeuta, ocupacao.NomePaciente, row.HorarioConsulta, ocupacao.DiaSemanaConsulta, ocupacao.CodigoTerapeuta, ocupacao.CodigoPaciente);"
                containerClass="customClass" tooltip="Libera Sala" placement="auto" [adaptivePosition]="false">
              </i>
            </div>
          </div>
        </td>
        <td [ngClass]="this.salasOcu[iDesc].Quinta[0].NomeTerapeuta == '' && this.salasOcu[iDesc].Quinta[0].NomePaciente == '' ? '' : 'bg-primary'">
          <div *ngFor="let ocupacao of row.Quinta; let indice = index">
            <div *ngIf="ocupacao.NomeTerapeuta != ''" [ngClass]="indice == 0 ? '' : 'linhaSuperior' "
              containerClass="customClass" tooltip="{{ocupacao.NomeTerapeuta}}" placement="auto"
              [adaptivePosition]="false">
              {{ocupacao.NomeTerapeuta}}</div>
            <div *ngIf="ocupacao.NomePaciente != ''">
              <label class="col-lg-11" containerClass="customClass" tooltip="{{ocupacao.NomePaciente}}" placement="auto"
                [adaptivePosition]="false">
                {{ocupacao.NomePaciente}}</label>
              <i *ngIf="row.CodigoSala != 9" class="fa-solid fa-trash"
                (click)="openModalExportar(confirmaLiberacao, row.CodigoSala,ocupacao.NomeTerapeuta, ocupacao.NomePaciente, row.HorarioConsulta, ocupacao.DiaSemanaConsulta, ocupacao.CodigoTerapeuta, ocupacao.CodigoPaciente);"
                containerClass="customClass" tooltip="Libera Sala" placement="auto" [adaptivePosition]="false">
              </i>
            </div>
          </div>
        </td>
        <td [ngClass]="this.salasOcu[iDesc].Sexta[0].NomeTerapeuta == '' && this.salasOcu[iDesc].Sexta[0].NomePaciente == '' ? '' : 'bg-primary'">
          <div *ngFor="let ocupacao of row.Sexta; let indice = index">
            <div *ngIf="ocupacao.NomeTerapeuta != ''" [ngClass]="indice == 0 ? '' : 'linhaSuperior' "
              containerClass="customClass" tooltip="{{ocupacao.NomeTerapeuta}}" placement="auto"
              [adaptivePosition]="false">
              {{ocupacao.NomeTerapeuta}}</div>
            <div *ngIf="ocupacao.NomePaciente != ''">
              <label class="col-lg-11" containerClass="customClass" tooltip="{{ocupacao.NomePaciente}}" placement="auto"
                [adaptivePosition]="false">
                {{ocupacao.NomePaciente}}</label>
              <i *ngIf="row.CodigoSala != 9" class="fa-solid fa-trash"
                (click)="openModalExportar(confirmaLiberacao, row.CodigoSala,ocupacao.NomeTerapeuta, ocupacao.NomePaciente, row.HorarioConsulta, ocupacao.DiaSemanaConsulta, ocupacao.CodigoTerapeuta, ocupacao.CodigoPaciente);"
                containerClass="customClass" tooltip="Libera Sala" placement="auto" [adaptivePosition]="false">
              </i>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

</div>

<ng-template #confirmaLiberacao>
  <div>
    <div class="modal-header">
      <h5 class="modal-title">Confirmação de Liberação</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"></span>
      </button>
    </div>
    <div class="modal-body">
      <p>Confirma a liberação da sala do terapeuta {{nomeTerapeuta}} com o paciente {{nomePaciente}}?</p>
      <p>A liberação da sala não encerra a consulta.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="LiberaSala()">Sim</button>
      <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="closeModal()">Não</button>
    </div>
  </div>
</ng-template>
