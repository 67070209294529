<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <div class="card-title-form">Adicionar nova Categoria
            <span class="float-end card-title-form-icone" containerClass="customClass" tooltip="Help" placement="top"
              [adaptivePosition]="false" href="#offcanvasExample" data-bs-toggle="offcanvas"
              aria-controls="offcanvasExample">
              <i class="fa-solid fa-circle-question"></i>
            </span>
          </div>

          <div class="card-body-form">
            <form [formGroup]="form">
              <div class="row">
                <div class="form-outline">
                  <label class="form-label" for="formDescricaoCategoria">Descrição da Categoria</label>
                  <input type="text" id="formDescricaoCategoria" formControlName="formDescricaoCategoria" [value]="form.get('formDescricaoCategoria')"
                    name="formDescricaoCategoria" class="form-control form-control-lg" />
                  <app-field-error-message [control]="form.get('formDescricaoCategoria')" label="descrição da categoria"></app-field-error-message>
                </div>
              </div>
              <div class="row">
                <div class="form-outline">
                    <div class="col-lg-3">
                        <label class="form-label" for="formPagaMensalidade">Paga mensalidade?</label>
                        <span class="d-block"> 
                            <ui-switch name="PagaMensalidade" id="PagaMensalidade" formControlName="formPagaMensalidade" size="large" 
                            [checked]="form.get('formPagaMensalidade')?.value" (change)="toggleDiv()">
                            </ui-switch>
                        </span>                        
                    </div>
                </div>
              </div>
              <div class="row" *ngIf="pagaMensalidade" id="formValorMensalidade">
                <div class="form-outline">
                    <div class="col-lg-3">
                        <label class="form-label" for="formValorMensalidade">Valor</label>
                        <input type="text" id="formValorMensalidade" formControlName="formValorMensalidade" [value]="form.get('formValorMensalidade')" 
                        name="formValorMensalidade" class="form-control form-control-lg" />
                        <app-field-error-message [control]="form.get('formValorMensalidade')" label="Valor"></app-field-error-message>
                    </div>
                </div>
              </div>
              <div class="top1">
                <div class="table-responsive linha-de-botoes">
                  <button type="button" class="btn btn-warning" (click)="voltar();">Cancelar</button>
                  <button [disabled]="form.invalid" type="button" class="btn btn-primary" 
                  (click)="SalvaCategoria();">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
