import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Associado, EnsinoMensalidade } from '../models/associados';
import { AssociadosService } from '../associados.service';
import { Lista } from '../../models-gerais/lista';
import { ListasGeraisService } from '../../listasgerais/listasgerais.service';

@Component({
  selector: 'app-associados-edicao-mensalidade',
  templateUrl: './associados-edicao-mensalidade.component.html',
  styleUrls: ['./associados-edicao-mensalidade.component.css'],
})
export class AssociadosEdicaoMensalidadeComponent implements OnInit {
  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  TipoMensalidade: Lista = new Lista();
  TipoLancamento: Lista = new Lista();
  mensalidade: EnsinoMensalidade = new EnsinoMensalidade();
  CodigoSocio: number = 0;
  NomeSocio: string = '';
  CodigoMensalidade: number = 0;
  configTipo = {
    search: true,
    placeholder: 'Selecione o tipo', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO+
    height: '200px',
    limitTo: 0
  };
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private associadosService: AssociadosService,
    private utilsservice: UtilsService,
    private listasGeraisService: ListasGeraisService
  ) {

  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    const { CodigoMensalidade, Sigla , CodigoSocio , NomeSocio } = window.history.state;
    this.CodigoMensalidade = this.mensalidade.CodigoMensalidade = CodigoMensalidade;
    this.mensalidade.TipoMensalidade = Sigla;
    this.CodigoSocio = this.mensalidade.CodigoEnsinoParticipante = CodigoSocio;
    this.NomeSocio = NomeSocio;
    this.criarForm();
    this.GetListasFixas();
  }

  criarForm() {
    this.form = this.formBuilder.group({
      TipoMensalidadeDescr: ['', [Validators.required, Validators.maxLength(50)]],
      ValorTotal: [0],
      DescontoVencimento: [0],
      DataVencimento: [''],
      ValorVencimento: [0],
      TarifaVencimento: [0],
      BloquetoGerado: [false],
      DataPagamento: [''],
      JuroVencimento: [0],
      ValorPagamento: [0],
      NomeTipoLancamento: [''],
      ValorPagamento2: [0],
      NomeTipoLancamento2: [''],
      DescricaoMensalidade: [''],
      bCompensado: [false]
    });
  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('TipoExtrato');  //A leitura das mensalidaters veio da VIEW extratos
    ArrayListas.push('TipoLancamento');
    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.TipoMensalidade = response.ListasFixas[0];
              this.TipoLancamento = response.ListasFixas[1];
              if(this.CodigoMensalidade != -1)
              { this.GetEnsinoMensalidadeByCodigo(); }
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  GetEnsinoMensalidadeByCodigo() {
    this.spinner.show();
    try {
      this.associadosService
        .GetEnsinoMensalidadeByCodigo(this.CodigoMensalidade, this.mensalidade.TipoMensalidade)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.mensalidade = response.EnsinoMensalidade;
              this.form.controls['ValorTotal'].setValue(this.mensalidade.ValorTotal);
              this.form.controls['DescontoVencimento'].setValue(this.mensalidade.DescontoVencimento);
              if (this.mensalidade.DataVencimento != null)
              { this.mensalidade.DataVencimento = Utils.getDateUTC(this.mensalidade.DataVencimento); }
              this.form.controls['DataVencimento'].setValue(this.mensalidade.DataVencimento);
              this.form.controls['ValorVencimento'].setValue(this.mensalidade.ValorVencimento);
              this.form.controls['TarifaVencimento'].setValue(this.mensalidade.TarifaVencimento);
              this.form.controls['BloquetoGerado'].setValue(this.mensalidade.BloquetoGerado);
              if (this.mensalidade.DataPagamento != null)
              { this.mensalidade.DataPagamento = Utils.getDateUTC(this.mensalidade.DataPagamento); }
              this.form.controls['DataPagamento'].setValue(this.mensalidade.DataPagamento);
              this.form.controls['JuroVencimento'].setValue(this.mensalidade.JuroVencimento);
              this.form.controls['ValorPagamento'].setValue(this.mensalidade.ValorPagamento);
              this.form.controls['ValorPagamento2'].setValue(this.mensalidade.ValorPagamento2);
              this.form.controls['DescricaoMensalidade'].setValue(this.mensalidade.DescricaoMensalidade);
              this.form.controls['bCompensado'].setValue(this.mensalidade.bCompensado);
            }
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }

  }

  voltar() {
    this.router.navigateByUrl('/cadastrogeral/listamens', {
      state: { CodigoSocio: this.CodigoSocio , NomeSocio: this.NomeSocio },
    });
  }

  SalvarVista() {
    //pagamento a vista
    this.form.controls['DataPagamento'].setValue(this.mensalidade.DataVencimento);
    this.form.controls['ValorPagamento'].setValue(this.mensalidade.ValorVencimento + this.mensalidade.TarifaVencimento);
    this.mensalidade.DataPagamento = this.mensalidade.DataVencimento;
    this.mensalidade.ValorPagamento = this.mensalidade.ValorVencimento + this.mensalidade.TarifaVencimento;
    this.Salvar();
  }

  Salvar() {

    if (this.mensalidade.TipoMensalidade == "EM") {this.messageService.showAlertDanger("Inscrição em atividade precisa ser feita no cadastro da atividade desejada."); }
    else {
      this.spinner.show();
      try {
        this.associadosService
          .SalvaMensalidade(this.mensalidade)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.voltar();
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

  AlteraData($event: any,data: string)
  {
    if (data == 'DataPagamento')
    {
      this.mensalidade.DataPagamento = new Date($event);
    }
    else if (data == 'DataVencimento')
    {
      this.mensalidade.DataVencimento = new Date($event);
    }
  }

  changeTipoMensalidade($event: any) {
    console.log($event);
    this.mensalidade.TipoMensalidade  = $event.value.Valor;
    this.mensalidade.TipoMensalidadeDescr = $event.value.Descricao;
  }

  changeBoleto($event: any) {
    this.mensalidade.BloquetoGerado = $event;
  }

  changeTipoLancamento($event: any, posicao: string) {
    if (posicao == "1")
    {
      this.mensalidade.CodigoTipoLancamento  = $event.value.Valor;
      this.mensalidade.NomeTipoLancamento = $event.value.Descricao;
    }
    else
    {
      this.mensalidade.CodigoTipoLancamento2  = $event.value.Valor;
      this.mensalidade.NomeTipoLancamento2 = $event.value.Descricao;
    }
  }

  changeCompensado($event: any) {
    this.mensalidade.bCompensado = $event;
    if(this.mensalidade.bCompensado)
    { this.mensalidade.Compensado = "S"; }
    else
    { this.mensalidade.Compensado = "N"; }
  }

  changeObs() {
    this.mensalidade.DescricaoMensalidade = this.form.get('DescricaoMensalidade')?.value;
  }

}
