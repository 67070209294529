export class Endereco {

  ENDERECO: string;
  COMPLEMENTO: string;
  CIDADE: string;
  BAIRRO: string;
  UF: string;
  CEP: string;

  constructor() {
    this.ENDERECO = '';
    this.COMPLEMENTO = "";
    this.CIDADE = '';
    this.BAIRRO = '';
    this.UF = "";
    this.CEP = '';
  }

}
