import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetCategoriaLista , RetCategoria, RetCategoriaHistoricoLista } from './models/ret-categoria';

import { AuthStorageService } from '../../Projeto/Autenticacao/auth-storage.service';
import { RetError } from 'src/app/Projeto/models/ret-error';

@Injectable({
  providedIn: 'root'
})

export class CategoriaService {

  private readonly baseURL: string = '';
  constructor(private httpClient: HttpClient, private router: Router, private authStorageService: AuthStorageService) {
    this.baseURL = `${environment.CategoriaWS}`;
  }

  GetListaGeral(): Observable<RetCategoriaLista> {

    const url = `${this.baseURL}/ListaGeral`;
    return this.httpClient.get<RetCategoriaLista>(url).pipe(
      take(1),
      map((response: RetCategoriaLista) => {
        return response;
      })
    );

  }

  GetListaCategoriaHistorico(Codigosocio: number): Observable<RetCategoriaHistoricoLista> {

    const params = new HttpParams()
    .set('Codigosocio', Codigosocio)

    const url = `${this.baseURL}/ListaCategoriaHistorico`;
    return this.httpClient.get<RetCategoriaHistoricoLista>(url,
      { "params": params }).pipe(
      take(1),
      map((response: RetCategoriaHistoricoLista) => {
        return response;
      })
    );

  }

}
