import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../Projeto/message/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssociadosService } from '../associados.service';
import { AssociadoEdicaoService } from '../associadosedicao.service'
import { Utils } from 'src/app/Projeto/Utils/utils';
import { UtilsService } from 'src/app/Projeto/Utils/utils.service';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { FormUtils } from 'src/app/Projeto/Utils/form-utils';
import { Lista } from '../../models-gerais/lista';
import { Endereco } from '../../models-gerais/Endereco';
import { Associado, SocioResumo } from '../models/associados';
import { ListasGeraisService } from '../../listasgerais/listasgerais.service';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';

@Component({
  selector: 'app-associados-edicao-end',
  templateUrl: './associados-edicao-end.component.html',
  styleUrls: ['./associados-edicao-end.component.css'],
})

export class AssociadosEdicaoEndComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    private spinner: NgxSpinnerService,
    private associadosService: AssociadosService,
    public associadosEdicao: AssociadoEdicaoService,
    private utilsservice: UtilsService,
    private listasGeraisService: ListasGeraisService
  ) {

  }

  public readonly uploadedFile: BehaviorSubject<any> = new BehaviorSubject(null);

  private subscription: Subscription;

  public readonly control = new FileUploadControl(
      { listVisible: true, accept: ['image/*'], discardInvalid: true, multiple: false },
      [FileUploadValidators.accept(['image/*']), FileUploadValidators.filesLimit(1)]
  );

  TipoResidencia: Lista = new Lista();

  configTipo = {
    search: true,
    placeholder: 'Selecione o tipo', //TROCA MENSAGEM
    noResultsFound: 'Nenhum registro encontrado!',
    searchPlaceholder: 'Pesquise...',
    moreText: 'Mais...',
    displayKey: 'Descricao', //TROCA CAMPO DESCRIÇÃO+
    height: 'auto',
    limitTo: 0
  };

  mensagem: string = '';
  Iscarga: boolean = true;
  @Input() associado: Associado = new Associado();
  AssociadoObj: SocioResumo = new SocioResumo();
  form: FormGroup;
  endPrincipal: Endereco = new Endereco();

  // Propriedade necessário para que a classe static FormUtils possa ser utilizada no Html
  get FormUtils(): typeof FormUtils {
    return FormUtils;
  }

  //Precisa deste tratamento em todas as telas que tem NGX-DATATABLE
  isMobile: boolean = false;
  ngOnInit(): void {
    this.isMobile = Utils.getIsMobile();
    window.onresize = () => {
      this.isMobile = Utils.getIsMobile();
    };
    this.Iscarga = true;
    this.subscription = this.control.valueChanges.subscribe((values: Array<File>) => this.getImage(values[0]));
    this.Iscarga = false;
    if(this.associado.ArqAssinaturaSocioBase64 != '')
    {
      let files: File[] = [];
      files.push(Utils.base64toFile(this.associado.ArqAssinaturaSocioBase64));
      this.control.setValue(files);
    }
    this.criarForm();

    this.form.controls['CepSocio'].setValue(this.associado.CepSocio);
    this.form.controls['EnderecoSocio'].setValue(this.associado.EnderecoSocio);
    this.form.controls['ComplementoSocio'].setValue(this.associado.ComplementoSocio);
    this.form.controls['BairroSocio'].setValue(this.associado.BairroSocio);
    this.form.controls['CidadeSocio'].setValue(this.associado.CidadeSocio);
    this.form.controls['UFSocio'].setValue(this.associado.UFSocio);
    this.form.controls['CepSocio2'].setValue(this.associado.CepSocio2);
    this.form.controls['EnderecoSocio2'].setValue(this.associado.EnderecoSocio2);
    this.form.controls['ComplementoSocio2'].setValue(this.associado.ComplementoSocio2);
    this.form.controls['BairroSocio2'].setValue(this.associado.BairroSocio2);
    this.form.controls['CidadeSocio2'].setValue(this.associado.CidadeSocio2);
    this.form.controls['UFSocio2'].setValue(this.associado.UFSocio2);

    this.GetListasFixas();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getImage(file: File): void {
    if (FileReader && file) {
      const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = (e: any) => {
          this.uploadedFile.next(e.target.result);
          this.changeImagem();
        }
        this.associado.ArqAssinaturaSocio = file.name;
        this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocio'].setValue(file.name);
    } else {
        this.uploadedFile.next('');
        if (!this.Iscarga)
        {
          this.associado.ArqAssinaturaSocio = '';
          this.associado.ArqAssinaturaSocioBase64 = '';
          this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocio'].setValue('');
          this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocioBase64'].setValue('');
          const options: any = document.querySelectorAll(`input[name="tipo"]`);
          for (const option of options) {
            if (option.checked) {
              option.checked = false;
            }
          }
        }
    }
  }

  public changeImagem() {
      this.associado.ArqAssinaturaSocioBase64 = this.uploadedFile.value.substring(this.uploadedFile.value.indexOf(",") + 1);
      this.associadosEdicao.EdicaoEndereco.controls['ArqAssinaturaSocioBase64'].setValue(this.uploadedFile.value.substring(this.uploadedFile.value.indexOf(",") + 1));
  }

  ChangeTipoSocio($event: any) {
    this.associadosEdicao.EdicaoPrio.controls['TipoSocio'].setValue($event.target.value);
    this.associado.TipoSocio = $event.target.value;
  }

  GetListasFixas(): void {
    this.spinner.show();

    let ArrayListas: string[] = [];
    ArrayListas.push('TipoEndereco');

    try {
      this.listasGeraisService
        .GetListasFixas(ArrayListas)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response.Error) {
              this.messageService.showAlertDanger(response.ErrorMessage);
            } else {
              this.TipoResidencia = response.ListasFixas[0];
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            this.utilsservice.showHttpError(error);
          },
        });
    } catch (error) {
      this.spinner.hide();
      this.utilsservice.showHttpError(error);
    }
  }

  changeAssociado($event: SocioResumo) {
    this.AssociadoObj = $event;
    this.associado.CodigoSocioEnc = this.AssociadoObj.CodigoSocio;
    this.associadosEdicao.EdicaoEndereco.controls['CodigoSocioEnc'].setValue(this.AssociadoObj.CodigoSocio);
    this.associado.NomeSocioEnc = this.AssociadoObj.NomeSocio;
    this.associadosEdicao.EdicaoEndereco.controls['NomeSocioEnc'].setValue(this.AssociadoObj.NomeSocio);
  }

  changeTipoEnd($event: any) {
    this.associado.TipoEnderecoSocio = $event.value.Valor;
    this.associadosEdicao.EdicaoEndereco.controls['TipoEnderecoSocio'].setValue($event.value.Valor);
    this.associado.TipoEnderecoSocioDescr = $event.value.Descricao;
  }

  changeTipoEnd2($event: any) {
    this.associado.TipoEnderecoSocio2 = $event.value.Valor;
    this.associadosEdicao.EdicaoEndereco.controls['TipoEnderecoSocio2'].setValue($event.value.Valor);
    this.associado.TipoEnderecoSocioDescr2 = $event.value.Descricao;
  }

  changeCEP() {
    this.associado.CepSocio = this.form.get('CepSocio')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['CepSocio'].setValue(this.form.get('CepSocio')?.value);
  }

  changeEndereco() {
    this.associado.EnderecoSocio = this.form.get('EnderecoSocio')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio'].setValue(this.form.get('EnderecoSocio')?.value);
  }

  changeCompl() {
    this.associado.ComplementoSocio = this.form.get('ComplementoSocio')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio'].setValue(this.form.get('ComplementoSocio')?.value);
  }

  changeBairro() {
    this.associado.BairroSocio = this.form.get('BairroSocio')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['BairroSocio'].setValue(this.form.get('BairroSocio')?.value);
  }

  changeCidade() {
    this.associado.CidadeSocio = this.form.get('CidadeSocio')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio'].setValue(this.form.get('CidadeSocio')?.value);
  }

  changeUF() {
    this.associado.UFSocio = this.form.get('UFSocio')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['UFSocio'].setValue(this.form.get('UFSocio')?.value);
  }

  changeCEP2() {
    this.associado.CepSocio2 = this.form.get('CepSocio2')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['CepSocio2'].setValue(this.form.get('CepSocio2')?.value);
  }

  changeEndereco2() {
    this.associado.EnderecoSocio2 = this.form.get('EnderecoSocio2')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio2'].setValue(this.form.get('EnderecoSocio2')?.value);
  }

  changeBairro2() {
    this.associado.BairroSocio2 = this.form.get('BairroSocio2')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['BairroSocio2'].setValue(this.form.get('BairroSocio2')?.value);
  }

  changeCidade2() {
    this.associado.CidadeSocio2 = this.form.get('CidadeSocio2')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio2'].setValue(this.form.get('CidadeSocio2')?.value);
  }

  changeUF2() {
    this.associado.UFSocio2 = this.form.get('UFSocio2')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['UFSocio2'].setValue(this.form.get('UFSocio2')?.value);
  }

  changeCompl2() {
    this.associado.ComplementoSocio2 = this.form.get('ComplementoSocio2')?.value;
    this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio2'].setValue(this.form.get('ComplementoSocio2')?.value);
  }

  criarForm() {
    this.form = this.formBuilder.group({
      CepSocio: ['', [Validators.maxLength(8)] ],
      EnderecoSocio: ['', [Validators.maxLength(120)] ],
      ComplementoSocio: ['', [Validators.maxLength(40)] ],
      BairroSocio: ['', [Validators.maxLength(40)] ],
      CidadeSocio: ['', [Validators.maxLength(40)] ],
      UFSocio: ['', [Validators.maxLength(2)] ],
      TipoEnderecoSocioDescr: [this.associado.TipoEnderecoSocioDescr, [Validators.maxLength(1)] ],
      CepSocio2: ['', [Validators.maxLength(8)] ],
      EnderecoSocio2: ['', [Validators.maxLength(120)] ],
      ComplementoSocio2: ['', [Validators.maxLength(40)] ],
      BairroSocio2: ['', [Validators.maxLength(40)] ],
      CidadeSocio2: ['', [Validators.maxLength(40)] ],
      UFSocio2: ['', [Validators.maxLength(2)] ],
      TipoEnderecoSocioDescr2: [this.associado.TipoEnderecoSocioDescr2, [Validators.maxLength(1)] ],
      CodigoSocioEnc: [this.associado.CodigoSocioEnc, [Validators.maxLength(80)] ],
      ArqAssinaturaSocio: [this.associado.ArqAssinaturaSocio, [Validators.maxLength(120)]],
    });
  }

  onFocusOutCEP(event: any, origem: string) {
    if (event.target.value != '' && event.target.value != undefined) {
      const CEP = event.target.value;
      this.spinner.show();
      try {
        this.associadosService
          .BuscaCep(CEP)
          .pipe(take(1))
          .subscribe({
            next: (response) => {
              this.spinner.hide();
              if (response.Error) {
                this.messageService.showAlertDanger(response.ErrorMessage);
              } else {
                this.endPrincipal = response.Endereco;

                if (origem == 'P') {
                  this.form.controls['EnderecoSocio'].setValue(this.endPrincipal.ENDERECO);
                  this.associado.EnderecoSocio = this.endPrincipal.ENDERECO;
                  this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio'].setValue(this.endPrincipal.ENDERECO);

                  this.form.controls['ComplementoSocio'].setValue(this.endPrincipal.COMPLEMENTO);
                  this.associado.ComplementoSocio = this.endPrincipal.COMPLEMENTO;
                  this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio'].setValue(this.endPrincipal.COMPLEMENTO);

                  this.form.controls['BairroSocio'].setValue(this.endPrincipal.BAIRRO);
                  this.associado.BairroSocio = this.endPrincipal.BAIRRO;
                  this.associadosEdicao.EdicaoEndereco.controls['BairroSocio'].setValue(this.endPrincipal.BAIRRO);

                  this.form.controls['CidadeSocio'].setValue(this.endPrincipal.CIDADE);
                  this.associado.CidadeSocio = this.endPrincipal.CIDADE;
                  this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio'].setValue(this.endPrincipal.CIDADE);

                  this.form.controls['UFSocio'].setValue(this.endPrincipal.UF);
                  this.associado.UFSocio = this.endPrincipal.UF;
                  this.associadosEdicao.EdicaoEndereco.controls['UFSocio'].setValue(this.endPrincipal.UF);

                  let CompP: any = document.getElementById(
                    'ComplementoSocio'
                  ) as HTMLInputElement | null;
                  if (CompP != null) {
                    CompP.focus();
                    CompP.select();
                  }

                } else {
                  this.form.controls['EnderecoSocio2'].setValue(this.endPrincipal.ENDERECO);
                  this.associado.EnderecoSocio2 = this.endPrincipal.ENDERECO;
                  this.associadosEdicao.EdicaoEndereco.controls['EnderecoSocio2'].setValue(this.endPrincipal.ENDERECO);

                  this.form.controls['ComplementoSocio2'].setValue(this.endPrincipal.COMPLEMENTO);
                  this.associado.ComplementoSocio2 = this.endPrincipal.COMPLEMENTO;
                  this.associadosEdicao.EdicaoEndereco.controls['ComplementoSocio2'].setValue(this.endPrincipal.COMPLEMENTO);

                  this.form.controls['BairroSocio2'].setValue(this.endPrincipal.BAIRRO);
                  this.associado.BairroSocio2 = this.endPrincipal.BAIRRO;
                  this.associadosEdicao.EdicaoEndereco.controls['BairroSocio2'].setValue(this.endPrincipal.BAIRRO);

                  this.form.controls['CidadeSocio2'].setValue(this.endPrincipal.CIDADE);
                  this.associado.CidadeSocio2 = this.endPrincipal.CIDADE;
                  this.associadosEdicao.EdicaoEndereco.controls['CidadeSocio2'].setValue(this.endPrincipal.CIDADE);

                  this.form.controls['UFSocio2'].setValue(this.endPrincipal.UF);
                  this.associado.UFSocio2 = this.endPrincipal.UF;
                  this.associadosEdicao.EdicaoEndereco.controls['UFSocio2'].setValue(this.endPrincipal.UF);


                  let CompS: any = document.getElementById(
                    'ComplementoSocio2'
                  ) as HTMLInputElement | null;
                  if (CompS != null) {
                    CompS.focus();
                    CompS.select();
                  }

                }
              }
            },
            error: (error) => {
              this.spinner.hide();
              this.utilsservice.showHttpError(error);
            },
          });
      } catch (error) {
        this.spinner.hide();
        this.utilsservice.showHttpError(error);
      }
    }
  }

}
