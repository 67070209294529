export class Sala {
  CodigoSala: number;
  NomeSala: string;
  TaxaSala: number;
  SalaOnLine: boolean;
  Ordenacao: string;

  constructor() {
    this.CodigoSala = 0;
    this.NomeSala = '';
    this.TaxaSala = 0;
    this.SalaOnLine = false;
    this.Ordenacao = '';
  }

}

export class SalvaSalaParametros {
  CodigoSala: number;
  NomeSala: string;
  TaxaSala: number;
  SalaOnLine: boolean;
  SalvaValorTodas: boolean;

  constructor() {
    this.CodigoSala = 0;
    this.NomeSala = '';
    this.TaxaSala = 0;
    this.SalaOnLine = false;
    this.SalvaValorTodas = false;
  }
}
