<app-header></app-header>

<div class="content-wrapper">
  <div class="container-fluid">
    <div>

      <div class="card-title-form col-lg-12">Rotinas</div>

      <div class="table-responsive table-responsive-overflow-450 bottom1">
        <form [formGroup]="form">

          <div class="row top1">
            <div class="card bg-relato">
              <div class="card-header">Taxa Associativa Manutenção</div>
              <div class="card-body row">
                <span class="col-lg-6">
                  <label class="form-label" style="width: 100%;">Data:</label>
                  <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                    [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                    name="DataInicial" id="DataInicial" formControlName="DataInicial"
                    (bsValueChange)="changeDtIni($event)" [bsValue]="dataInicial">
                </span>
                <span class="col-lg-6" style="align-content: end;">
                  <button type="button" class="btn btn-sm btn-warning w-100" (click)="Gera('SalaUso');">Gerar</button>
                </span>
              </div>
            </div>
            <div class="card bg-relato">
              <div class="card-header">Contribuição Associativa</div>
              <div class="card-body row">

                  <span class="col-lg-6">
                    <label class="form-label" for="retorno" style="width: 100%;">Data:</label>
                    <input style="display: block" class="form-control form-control-lg" bsDatepicker type="text"
                      [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' , containerClass: 'theme-dark-blue', showWeekNumbers: false, showTodayButton: true, todayPosition: 'center', todayButtonLabel: 'Hoje' }"
                      name="DataFinal" id="DataFinal" formControlName="DataFinal"
                      (bsValueChange)="changeDtFim($event)" [bsValue]="dataFinal">
                  </span>
                  <span class="col-lg-6" style="align-content: end;">
                    <button type="button" class="btn btn-sm btn-warning w-100" (click)="Gera('Mensalidade');">Gerar</button>
                  </span>

              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>
