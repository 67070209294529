export class Payload {
  userId: string;
  userName: string;
  sub: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;

  constructor() {
    this.userId = '';
    this.userName = '';
    this.sub = '';
    this.iss = '';
    this.aud = '';
    this.iat = 0;
    this.exp = 0;
  }
}
